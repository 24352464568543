import React from 'react';

import facebook from "../../img/icons/facebook.webp"
import apple from "../../img/icons/apple.webp"
import google from "../../img/icons/google.webp"

export default function PageSocialLogin() {
  
  return (
    <>
    <div className="signinup-social-login-bx">
        <div className="sslb-or-div">
            <div className="after-befor-line"></div>
            <span>or continue with</span>
            <div className="after-befor-line"></div>
        </div>
        <div className="sslb-btn-bx">
            <div className="sslb-btn-item">
                <img src={google} alt="" />
                <div className="sslb-text-popup">Google</div>
            </div>

            <div className="sslb-btn-item">
                <img src={apple} alt="" />
                <div className="sslb-text-popup">Apple</div>
            </div>

            <div className="sslb-btn-item">
                <img src={facebook} alt="" />
                <div className="sslb-text-popup">Facebook</div>
            </div>
        </div>
    </div>
    </>
  );
};
