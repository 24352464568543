import React, { useState, useEffect, useRef } from "react"; 
import { Link } from 'react-router-dom'
import { useAuth, getLoggedInUserData } from "../context/AuthContext";
import useApi from "../utility/apiCall";
import API_URL from "../config/config";

// Common Icons & img file
import Icons from '../components/icons'

export default function VerificationStatus2() {

  const [verifiedStep, setVerifiedStep] = useState("verification-status-bx2");
  const [completedSteps, setCompletedSteps] = useState(0);
  const [verificationLevel, setVerificationLevel] = useState(null);
  const { apiCall } = useApi();
  const [user, setUser] = useState("");
  const { state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const isInitialMount = useRef(true);
  const token = state.token || "";
  const fetchVerification = async () => {
    
    try {
      const response = await apiCall(
        API_URL + "get-user-verification-level",
        {
          user_id: state?.userData?.id,
          token: state?.token,
        },
        {}
      );
      if (response.data.success == "0") {
        //setVerifyEmailPhone(response.data.data);
       // setShowOtpLoader(false);
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };
  
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      if (isUserLoggedIn) {
        setUserLoggedIn(true);
        setUser(loggedInUser);
      }
      fetchVerification();
      
    }
  }, [state.userData]);

    useEffect(()=>{
      if (state.userData.step3_verified) {
        setVerificationLevel("Fully verified");
        setVerifiedStep("verified-step3")
        setCompletedSteps(3);
      } else if (state.userData.step2_verified) {
        setVerificationLevel("Partially verified");
        setVerifiedStep("verified-step2")
        setCompletedSteps(2);
      } else if (state.userData.step1_verified) {
        setVerificationLevel("Partially verified");
        setVerifiedStep("verified-step1")
        setCompletedSteps(1);
      } else {
        setVerificationLevel("Unverified");
        setVerifiedStep("verification-status-bx2") // Or some default value
        setCompletedSteps(0);
      }
    },[state.userData]);

  return (
  <div className="common-box">
    <div className="common-box-heading">
      <Icons.VerificationSvg />
      Verification Status
    </div>

    {/* verified-step1, verified-step2, verified-step3 */}
    <div className={"verification-status-bx2 "+verifiedStep}>
      <div className="vsb2-data-bx">
        <div className="vdb-icons-bx">
          <div className="vdb-icon-circle">
            <Icons.VerificationCircleSvg />
          </div>
          <Icons.UserSvg />
        </div>
        <div className="vdb-inner">
          {/* Not verified, Partially verified, Fully verified */}
          <div className={`vsb2-current ${verificationLevel}`}>{verificationLevel}</div>
          <div className="vsb2-steps">{completedSteps} / 3 steps complete</div>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-2">
        <Link to="/verifications" className="common-btn-item cbi-fill">
          <span>View Details</span>
        </Link>
      </div>
    </div>
  </div>
  )
}
