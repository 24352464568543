import React from 'react'

// Icon Files
// import Icons from '../components/icons';
// import TransactionDetailModal from './TransactionDetailModal';
 
//export default function TransactionItem() {
  const TransactionItem = ({ icon, date, id, mt5_number,account,balance, method, amount, transfer_type, status, statusColor, onClick }) => (
    <>
    <tr onClick={onClick}>
        <td>{id}</td>
        <td>{date}</td>
        <td>{icon}{transfer_type}</td>
        <td>{mt5_number}</td>
        {/* <td>{account}</td> */}
        <td>{method}</td>
        <td>{balance}</td>
        <td>{amount}</td>
        <td className= {`${statusColor}`}>{status}</td>
    </tr>
    
    {/* <div className="col-12 d-none">
      <div className="transaction-item" onClick={onClick}>
        <div className="ti-inner ti-i-pl">
          <div className={`ti-i-icon ${statusColor}`}>{icon}</div>
          <div className="ti-i-info">{date}</div>
          <div className="ti-i-data">{id}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Type</div>
          <div className="ti-i-data">{transfer_type}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Account</div>
          <div className="ti-i-data">{account}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Payment Method</div>
          <div className="ti-i-data">{method}</div>
        </div>
        <div className="ti-inner">
          <div className="ti-i-info">Amount</div>
          <div className="ti-i-data tit-color2">{amount}</div>
        </div>
       
        <div className="ti-inner">
          <div className="ti-i-info">Status</div>
          <div className={`ti-i-data ${statusColor}`}>{status}</div>
        </div>
      </div>
    </div> */}
    </>
  );
  
  export default TransactionItem;
