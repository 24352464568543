import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage';
// Design Files
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';

// Images Common File
import * as Img from '../../components/Img';

export default function PageSignOTP() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { userOtp = '', userId = '', email_phone = '' ,userEmail = '', token = '', type = '' } = location.state || {};

  const [otpError, setOtpError] = useState(false);
  const [verificationSuccsess, setVerificationSuccsess] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [startTimer, setStarttimer] = useState(false);

  useEffect(() => {
    if (!userId) {
      navigate('/restore')
    }
  }, [userOtp, userId]);

  let initialOtp;
  if (typeof userOtp === 'number') {
    initialOtp = userOtp.toString().split('').slice(0, 4);
  } else {
    initialOtp = ['', '', '', ''];
  }

  const [otp, setOtp] = useState(initialOtp);

  // const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === '' && index > 0) {
      // If backspace is pressed and the input field is empty, move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (index > 0) {
        // Move focus to the previous input
        inputRefs[index - 1].current.focus();
      }
      // Clear the current input
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleContinueClick = async () => {
    setIsLoading(true);
    setVerificationSuccsess(false);
    setVerificationError(false);
    if (otp && otp.every(digit => digit !== '')) {
      if (!token && !type) {
        try {
          const otpString = otp.join('');
          const response = await apiCall(API_URL +'verify-otp', {
            user_id: userId,
            otp: otpString,
          });
          if (response.data.success == '0') {
            setVerificationSuccsess(true);

            setTimeout(() => {
              navigate('/resetpassword', { state: { userId: response.data.data.id } });
            }, 2000);

          } else if (response.data.success == '2') {
            setVerificationError(true);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        try {
          const otpString = otp.join('');
          const response = await apiCall(API_URL +'verify-email-phone-otp', {
            token: token,
            user_id: userId,
            type: type,
            otp: otpString,
          });
          console.log(response.data)
          if (response.data.success == '0') {
            setVerificationSuccsess(true);
            setTimeout(() => {
              navigate('/');
            }, 2000);    
          } else if (response.data.success == '2') {
            setVerificationError(true);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      setIsLoading(false);
    } else {
      setOtpError(true);
    }
  };
  
  useEffect(() => {
    // console.log(startTimer);
      const countdown = setTimeout(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          clearTimeout(countdown);
        }
      }, 1000);
  }, [timer]);

  const sendVerifyOtp = async () => {   
    
    let data = type === 'phone' ? email_phone : userEmail;
    try {
      setIsLoading(true);
      let response;
      if(location.state.resend_type == "reset_password"){
        response = await apiCall(API_URL + 'send-reset-otp', {
          email: data,
        });
      }
      else if(location.state.resend_type == "verification"){
        response = await apiCall(API_URL + 'send-verify-otp', {
          token: token,
          user_id: userId,
          type: type,
          email_phone: data,
        });
      }
      if (response.data.success === '0') {
        // const user_otp = type === 'phone' ? response.data.data.phone_otp : response.data.data.email_otp;
        // setTimeout(() => {
        //   navigate('/restoreotp', { state: {userId: response.data.data.id, userEmail: response.data.data.email, token: token, type: type } });
        // }, 2000);
        setVerificationSuccsess(true);
      } else if (response.data.success === '2') {
        setVerificationError(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // const sendVerifyOtp = async () => {
  //   if (selectedOption == 'phone') {
  //     var data = newphoneNumber;
  //   } else {
  //     var data = newEmail;
  //   }
  //   try {
  //     setIsLoading(true);
  //     const response = await apiCall(API_URL +'send-verify-otp', {
  //       token: token,
  //       user_id: userId,
  //       type: selectedOption,
  //       email_phone: data,
  //     });
  //     if (response.data.success == '0') {
  //       if (selectedOption == 'phone') {
  //         var user_otp = response.data.data.phone_otp
  //       } else {
  //         var user_otp = response.data.data.email_otp
  //       }
  //       setTimeout(() => {
  //         navigate('/restoreotp', { state: { userOtp: user_otp, userId: response.data.data.id, userEmail: response.data.data.email, token: token, type: selectedOption } });
  //       }, 2000);
  //       setVerificationSuccsess(true);
  //     } else if (response.data.success == '2') {
  //       setVerificationError(true);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }

  // };
  
  return (
    <>
      <Helmet>
        <title>Restore a password | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn to="/verification" />

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className="signinup-screen">

            <div className="signinup-header">
              <div className="screen-hed">Enter your Security code</div>
              <div className="screen-pera">We texted your code to <span className="sp-email">{userEmail}</span></div>
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                  <div className="signinup-group otp-group">
                    {otp.map((digit, index) => (
                      <>
                        <input key={index} inputMode="numeric" type="text" maxLength="1" placeholder=" " value={digit} onChange={(e) => handleChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} ref={inputRefs[index]} />
                        {otpError && <span className="form-error-msg">Please enter otp</span>}
                      </>
                    ))}
                  </div>
                </div>

                <div className="col-12">
                  <div className="otp-resend-bx">
                    <div>Have Not Received Code</div>
                    {/* <div><div className="reotp-btn">Resend</div></div> */}
                    {/* <div>Resend In <span>30s</span></div> */}
                    {timer > 0 ? (
                          <div>
                          Resend In <span>{timer}s</span>
                          </div>
                      ) : (
                        <div><div className="reotp-btn" onClick={() => { sendVerifyOtp(); setTimer(30); }}>Resend</div></div>
                      )}
                  </div>
                </div>

                <div className="col-12">
                  {!isLoading && <button type="button" onClick={handleContinueClick} className="common-submit-btn">Continue</button>}

                  {isLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>

                {verificationSuccsess && <FlashMessage type="success" isvisible={true} message="Verified" />}
                {verificationError && <FlashMessage type="error" isvisible={true} message="Otp did not matched." />}

                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to
                    <Link to="/verification" className="signinup-link-two">Previous</Link>
                  </div>
                </div>

              </div>
            </form>

          </div>
        </div>
      </section>

    </>
  );
};
