import React from 'react';
import { useParams } from 'react-router-dom';
// Design File
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Deposit from './Deposit';



export default function PageDeposit() {
  const { id } = useParams();

  return (
    <>
      <Deposit wallet_id={id}/>
    </>
  )
}
