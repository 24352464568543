import React, { useState, useEffect, useRef } from "react";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
import { Link } from "react-router-dom";

import { Modal } from "react-bootstrap";

// Icons & img files
import Icons from "../../components/icons";
import walletIcon from "../../img/icons/paymentmethod/wallet.png";
import mt5 from "../../img/icons/paymentmethod/mt5.webp";
import DatePickerComponent from "../../components/CommonInput/DatePickerComponent";

// Demo Img
import europeanFlag from "../../img/icons/flag/european.png";
import usaFlag from "../../img/icons/flag/united-states-of-america.png";
import bitCoin from "../../img/icons/flag/bitcoin.png";
import EmptyBox from "../../components/EmptyBox";

// Design Files
import AnalyticsLayout from "./AnalyticsLayout";
import AccountDropItem from "../PageFunds/AccountDropItem";

export default function PageOrderHistory() {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || "";
  const isInitialMount = useRef(true);
  const [selectFromDate, setSelectFromDate] = useState(null);
  const [selectToDate, setSelectToDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporLoading, setIsExporLoading] = useState(false);
  const [walletOption, setWalletOption] = useState([]);
  const [selectedHeading, setSelectedHeading] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [statusFilter, setStatusFilter] = useState("close");
  const [perPage, setPerPage] = useState(100);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [trades, setTrades] = useState(null);
  const [orderBy, setOrderBy] = useState("open_date");
  const [orderColumn, setOrderColumn] = useState("asc");
  const [type, setType] = useState("close");
  const [selectedTrade, setSelectedTrade] = useState(null);
  // ---
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isOrderHistory, setIsOrderHistory] = useState(false);

  const handleOrderHistoryClose = () => {
    setIsOrderHistory(false);
    setSelectedTrade(null);
  };

  const handleOrderHistoryShow = (data) => {
    setIsOrderHistory(true);
    setSelectedTrade(data);
  };
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleOrderChange = (sort) => {
    const newOrderColumn =
      orderBy === sort && orderColumn === "asc" ? "desc" : "asc";
    setOrderBy(sort);
    setOrderColumn(newOrderColumn);
  };
  // ---

  const getUserWallet = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "get-user-wallets-trade", {
        user_id: state.userData.id,
        token: token,
      });
      if (response.data.success == "0") {
        const wallets = response.data.data;
        setWalletOption(wallets);
        if (wallets.length > 0) {
          setSelectedHeading(wallets[0].account_number);
          setSelectedId(wallets[0].id);
          setSelectedWallet(wallets[0]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getUserTradeHistory = async (type) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "get-mt5-trade", {
        user_id: state.userData.id,
        user_wallet_id: selectedId,
        token: token,
        page: page,
        per_page: perPage,
        type: type,
        order_by: orderBy,
        order_column: orderColumn,
        from_date: selectFromDate,
        to_date_: selectToDate,
      });
      if (response.data.success == "0") {
        setTrades(response.data.data.data);
        setTotalPages(response.data.data.last_page);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const getUserTradeHistoryExport = async () => {
    try {
      setIsExporLoading(true);
      const response = await apiCall(
        API_URL + "get-mt5-trade-export-user",
        {
          user_id: state.userData.id,
          user_wallet_id: selectedId,
          token: token,
          page: page,
          per_page: perPage,
          type: type,
          order_by: orderBy,
          order_column: orderColumn,
          from_date: selectFromDate,
          to_date_: selectToDate,
        },
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "trade_history_export.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsExporLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsExporLoading(false);
    }
  };

    const getSymbolImage = (symbolImages) => {
      // if (symbolImages.length == 2) {
      //     try {
      //       return (
      //         <div className="symbol-icons">
      //           <img src={symbolImages[0]} alt="" />
      //           <img src={symbolImages[1]} alt="" />
      //         </div>
      //       );
          
      //     } catch (error) {
      //       return ''; // Return an empty string if the image is not found
      //     }
        
      // } else if(symbolImages.length == 1){
      //   try {
      //     return (
      //       <div className="symbol-icons si-single">
      //         <img src={symbolImages[0]} alt="" />
      //       </div>
      //     );
      //     } catch (error) {
      //     return ''; // Return an empty string if the image is not found
      //     }
      // } else {
        return '';
      // }
	  };

  const DemoOnChange = () => {};

  const handleSelectionChange = (selectedWalletId) => {
    const selectedWallet = walletOption.find(
      (wallet) => wallet.id === selectedWalletId
    );
    if (selectedWallet) {
      setSelectedHeading(selectedWallet.account_number);
      setSelectedId(selectedWalletId);
      setSelectedWallet(selectedWallet);
    } else {
      console.error("Wallet not found with the provided ID");
    }
    // getUserTradeHistory(type);
  };
  const handleOrderStep = async (type) => {
    try {
      if (type === "close") {
        setType(type);
      }
      if (type === "open") {
        setType(type);
      }
      setSelectedTrade(null);
      setOrderBy(null);
      setOrderColumn("asc");
      setSelectFromDate(null);
      setSelectToDate(null);
      getUserTradeHistory(type);
    } catch (error) {
      console.error("Failed to fetch wallet group data:", error);
    }
  };
  const handleSelectFromDateChange = (date) => {
    setSelectFromDate(date);
  };
  const handleSelectToDateChange = (date) => {
    setSelectToDate(date);
  };
  const handleClear = () => {
    setSelectFromDate(null);
    setSelectToDate(null);
    setSelectedTrade(null);
    getUserTradeHistory(type);
  };
  useEffect(() => {
    getUserWallet();
  }, []);
  useEffect(() => {
    if (selectedId) {
      getUserTradeHistory(type);
    }
  }, [selectedId]);

  useEffect(() => {
    setOrderBy(null);
    setOrderColumn("asc");
    setSelectFromDate(null);
    setSelectToDate(null);
  }, [type]);

  useEffect(() => {
    if (orderBy) {
      getUserTradeHistory(type);
    }
  }, [orderBy, orderColumn]);

  useEffect(() => {}, [orderColumn]);

  useEffect(() => {
    if (selectFromDate && selectToDate) {
      getUserTradeHistory(type);
    }
  }, [selectFromDate, selectToDate]);
  return (
    <>
      <AnalyticsLayout>
        <div className="col-12">
          <div className="filter-bx afb-filter mt-1">
            <div className="filter-left">
              <AccountDropItem
                className="mb-0"
                label="Account"
                img={selectedWallet ? selectedWallet : walletIcon}
                selected={selectedWallet ? selectedWallet : walletIcon} // Assuming mt5 is the default image for now
                heading={selectedHeading} // Dynamically set heading
                options={walletOption}
                onChange={handleSelectionChange} // Handle selection change
              />
            </div>

            <div className="filter-right">
              <DatePickerComponent
                label="From Date"
                onChange={handleSelectFromDateChange}
                value={selectFromDate}
              />

              <DatePickerComponent
                label="To Date"
                onChange={handleSelectToDateChange}
                value={selectToDate}
              />

              <div
                className="d-flex flex-wrap justify-content-end gap-2"
                onClick={handleClear}
              >
                <div className="common-btn-item cbi-outline">
                  <span>Clear</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div
            class="common-btn-item cbi-fill cbi-small mb-3 ms-auto"
            onClick={getUserTradeHistoryExport}
          >
            <span>
              <Icons.ArrowexportSvg />
              Export
            </span>
          </div>
          <div className="common-box">
            <div className="common-tabs-bx ctb-style-2 mt-2 mb-3">
              <div
                className={`ctb-item ${type === "close" ? "active" : ""}`}
                onClick={() => handleOrderStep("close")}
              >
                Closed trades
              </div>
              <div
                className={`ctb-item ${type === "open" ? "active" : ""}`}
                onClick={() => handleOrderStep("open")}
              >
                Open orders
              </div>
            </div>

            <div className="table-responsive">
              {trades && trades.length > 0 && !isLoading && (
                <table className="custom-table ct-hover ct-last-td-sticky">
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Type</th>
                      <th
                        className={
                          orderBy === "open_date" ? `active ${orderColumn}` : ""
                        }
                        onClick={() => handleOrderChange("open_date")}
                      >
                        Opening time {" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th
                        className={
                          orderBy === "close_date"
                            ? `active ${orderColumn}`
                            : ""
                        }
                        onClick={() => handleOrderChange("close_date")}
                      >
                        Closing time {" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th
                        className={
                          orderBy === "lots" ? `active ${orderColumn}` : ""
                        }
                        onClick={() => handleOrderChange("lots")}
                      >
                        Lots{" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th
                        className={
                          orderBy === "open_price"
                            ? `active ${orderColumn}`
                            : ""
                        }
                        onClick={() => handleOrderChange("open_price")}
                      >
                        Opening price{" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th
                        className={
                          orderBy === "close_price"
                            ? `active ${orderColumn}`
                            : ""
                        }
                        onClick={() => handleOrderChange("close_price")}
                      >
                        Closing price{" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th
                        className={
                          orderBy === "take_profit"
                            ? `active ${orderColumn}`
                            : ""
                        }
                        onClick={() => handleOrderChange("take_profit")}
                      >
                        Profit{" "}
                        <div className="th-sort-icons">
                          <Icons.CaretUpSvg />
                          <Icons.CaretDownSvg />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {trades.map((trades, index) => (
                      <tr onClick={() => handleOrderHistoryShow(trades)}>
                        <td>
                          <div className="symbol-bx">
                            {getSymbolImage(trades.symbol.symbol_images)}
                            {trades.symbol_name}
                          </div>
                        </td>
                        <td
                          className={`${
                            trades.side === "buy" ? "td-green" : "td-red"
                          }`}
                        >
                          {trades.side}
                        </td>
                        <td>
                          {trades.open_date ? trades.open_date_formattad : "-"}
                        </td>
                        <td>
                          {trades.close_date
                            ? trades.close_date_formattad
                            : "-"}
                        </td>
                        <td>{trades.lots}</td>
                        <td>
                          {trades.open_price
                            ? trades.open_price.toFixed(5)
                            : "-"}
                        </td>
                        <td>
                          {trades.close_price
                            ? trades.close_price.toFixed(5)
                            : "-"}
                        </td>
                        <td
                          className={`${
                            trades.profit > 0 ? "td-green" : "td-red"
                          }`}
                        >
                          {(trades.profit == 0 && trades.loss == 0)
                            ? '-'
                            : trades.profit > 0
                            ? trades.profit.toFixed(5)
                            : "- " + trades.loss.toFixed(5)}
                        </td>
                        <td>
                          <div className="td-view">
                            <Icons.DownSvg />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {trades && trades.length == 0 && !isLoading && (
                <EmptyBox
                  className="empty-div-bx"
                  eh="No data found"
                  esh="Try adjusting filters to get results."
                />
              )}
              {isLoading && (
                <>
                  <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
                  <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
                  <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
                  <div className="skeletant-bx skeletant-design sh-47"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </AnalyticsLayout>

      {/* --- Order History Modal --- */}
      <Modal
        className="zoom custom-content"
        centered
        show={isOrderHistory}
        onHide={handleOrderHistoryClose}
      >
        <div className="custom-modal-header">
          <div className="cmh-lable">History of order</div>

          <span className="close-icon" onClick={handleOrderHistoryClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </span>
        </div>
        {selectedTrade && (
          <div className="custom-modal-body">
            <div className="ac-info-item">
              <div className="ai-inner">
                <div className="ai-i-info">Symbol</div>
                <div className="ai-i-data symbol-bx">
                  {/* <div className="symbol-icons">
                    <img src={europeanFlag} alt="" />
                    <img src={usaFlag} alt="" />
                  </div> */}
                  {getSymbolImage(selectedTrade.symbol.symbol_images)}
                  {selectedTrade.symbol_name}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Type</div>
                <div
                  className={`ai-i-data ${
                    selectedTrade.side === "buy" ? "color-green" : "color-red"
                  }`}
                >
                  {selectedTrade.side ? selectedTrade.side : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Opening time</div>
                <div className="ai-i-data">
                  {selectedTrade.open_date
                    ? selectedTrade.open_date_formattad
                    : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Closing time</div>
                <div className="ai-i-data">
                  {selectedTrade.close_date
                    ? selectedTrade.close_date_formattad
                    : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Lots</div>
                <div className="ai-i-data">
                  {selectedTrade.lots ? selectedTrade.lots : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Opening price</div>
                <div className="ai-i-data">
                  {selectedTrade.open_price ? selectedTrade.open_price.toFixed(5) : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Closing price</div>
                <div className="ai-i-data">
                  {selectedTrade.close_price ? selectedTrade.close_price.toFixed(5) : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Profit</div>
                <div className={`ai-i-data ${
                    selectedTrade.profit >0 ? "color-green" : "color-red"
                  }`}>
                  {(selectedTrade.profit == 0 && selectedTrade.loss == 0)
                    ? '-'
                    : selectedTrade.profit > 0
                    ? selectedTrade.profit.toFixed(5)
                    : "- " + selectedTrade.loss.toFixed(5)}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Trade ID</div>
                <div className="ai-i-data">
                  {selectedTrade.trade_id ? selectedTrade.trade_id : "-"}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Commission</div>
                <div className="ai-i-data">
                  {selectedTrade.commission ? selectedTrade.commission : 0}
                </div>
              </div>

              <div className="ai-inner">
                <div className="ai-i-info">Swap</div>
                <div className="ai-i-data">
                  {selectedTrade.swap ? selectedTrade.swap : 0}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
