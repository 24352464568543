import React from 'react';
//import { GoogleLogin } from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'; 
import facebook from "../../img/icons/facebook.webp";
import apple from "../../img/icons/apple.webp";
import google from "../../img/icons/google.webp";
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function PageSocialLogin() {
  
  const clientId = '733526859691-3flcogutj765slaaaup9d6rmq02sv6cj.apps.googleusercontent.com';
  const facebookAppId = '961337572061098'; 

  const handleGoogleLoginSuccess = (response) => {
    console.log('Google login success:', response);
    // Handle successful login here
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google login error:', error);
    // Handle failed login here
  };

  const handleFacebookLoginSuccess = (response) => {
    console.log('Facebook login success:', response);
    // Handle successful Facebook login here
  };

  const handleFacebookLoginFailure = (response) => {
    console.error('Facebook login error:', response);
    // Handle failed Facebook login here
  };

  return (
    <>
      <div className="signinup-social-login-bx">
          <div className="sslb-or-div">
              <div className="after-befor-line"></div>
              <span>or continue with</span>
              <div className="after-befor-line"></div>
          </div>
          <div className="sslb-btn-bx">
              <div className="sslb-btn-item">
             
                  {/* <GoogleLogin
                    clientId={clientId}
                    onSuccess={handleGoogleLoginSuccess}
                    onFailure={handleGoogleLoginFailure}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                      <button onClick={renderProps.onClick}>
                        <img src={google} alt="" />
                        <div className="sslb-text-popup">Google</div>
                      </button>
                    )}
                  /> */}
                  <GoogleOAuthProvider clientId={clientId}>
                  <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>;
</GoogleOAuthProvider>
              </div>

              <div className="sslb-btn-item">
                  <img src={apple} alt="" />
                  <div className="sslb-text-popup">Apple</div>
              </div>

              <div className="sslb-btn-item">
                  <FacebookLogin
                    appId={facebookAppId}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={handleFacebookLoginSuccess}
                    onFailure={handleFacebookLoginFailure}
                    render={(renderProps) => (
                      <button onClick={renderProps.onClick}>
                        <img src={facebook} alt="" />
                        <div className="sslb-text-popup">Facebook</div>
                      </button>
                    )}
                  />
              </div>
          </div>
      </div>
    </>
  );
};
