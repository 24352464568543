import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

// Audio File
import clickAudio from '../img/audio/clickAudio.mp3'
import hoverAudio from '../img/audio/hoverAudio.mp3'
import swipeAudio from '../img/audio/swipeAudio.mp3'

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {

    // Click 1 Start
    const handleAudioClick = () => {
        const audio = new Audio(clickAudio);
        audio.play();
    };
    // Click 1 Start

    // Click 2 Start
    const handleAudioClick2 = () => {
        const audio = new Audio(swipeAudio);
        audio.play();
    };
    // Click 2 Start

    // Hover 1 Start
    const hoverAudioRef = useRef(null);
    const [userInteracted, setUserInteracted] = useState(false);

    const handleAudioHover = () => {
        if (hoverAudioRef.current && userInteracted) {
            hoverAudioRef.current.play();
        }
    };

    useEffect(() => {
    const handleUserInteraction = () => {
        setUserInteracted(true);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
        document.removeEventListener('click', handleUserInteraction);
    };
    }, []);
    // Hover 1 End

    // Hover 2 Start
    const hoverAudioRef2 = useRef(null);
    const [userInteracted2, setUserInteracted2] = useState(false);

    const handleAudioHover2 = () => {
        if (hoverAudioRef2.current && userInteracted2) {
            hoverAudioRef2.current.play();
        }
    };

    useEffect(() => {
    const handleUserInteraction2 = () => {
        setUserInteracted2(true);
    };

    document.addEventListener('click', handleUserInteraction2);

    return () => {
        document.removeEventListener('click', handleUserInteraction2);
    };
    }, []);
    // Hover 2 End

  return (
    <AudioContext.Provider value={{ handleAudioClick, handleAudioClick2, handleAudioHover, handleAudioHover2 }}>
        {/* --- Audio File for hover --- */}
        <audio ref={hoverAudioRef} preload="auto">
            <source src={hoverAudio} type="audio/mpeg" />
        </audio>

        <audio ref={hoverAudioRef2} preload="auto">
            <source src={swipeAudio} type="audio/mpeg" />
        </audio>
        {/* --- --- */}

        {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within a AudioContext');
  }
  return context;
};
