import React, { useState, useEffect, useRef } from "react";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import API_URL from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../utility/apiCall";
// Design File
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Withdraw from "./Withdraw";

export default function PageWithdraw() {
  const { type, id } = useParams();
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || "";
  const [verificationLoader, setVerificationLoader] = useState(false);
  const fetchVerification = async () => {
    try {
      setVerificationLoader(true);
      const response = await apiCall(API_URL + "get-user-verification-level", {
        user_id: state?.userData?.id,
        token: state?.token,
      });
      if (response.data.success == "0") {
        if (response.data.data.index === "0") {
          setTimeout(() => {
            navigate("/verifications", { state: { verificationErrorMsg: true } });
          }, 2000);
        }
      }
    } catch (error) {
      console.error("An error occurred");
      setVerificationLoader(false);
    }
  };
  useEffect(() => {
    fetchVerification();
  }, []);
  return (
    <>
      <Withdraw wallet_id={id} wallet_type={type} />
    </>
  );
}
