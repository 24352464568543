import React, { useState, useEffect } from 'react';
import { Link,useNavigate,useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage';

// Design Files
import PageHeader from './PageHeader';
import PageBackBtn from './PageBackBtn';

// Images Common File
import * as Img from '../../components/Img';

const hidepassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
  </svg>
  )
}

const viewpassSvg = () => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
  )
}

export default function PageResetPassword() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};
  // View passwords code
  const [showPasswords, setShowPasswords] = useState([false, false, false]);

  const togglePasswordVisibility = (index) => {
      const updatedShowPasswords = [...showPasswords];
      updatedShowPasswords[index] = !updatedShowPasswords[index];
      setShowPasswords(updatedShowPasswords);
  };


  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableBtn,setDisableBtn] = useState(false);
  const [passwordChangeSuccsess, setPasswordChangeSuccsess] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [diffrentPasswordError,setDiffrentPasswordError] = useState(false);
  const [passwordLengthError,setPasswordLengthError] = useState(false);

  const [isLoading,setIsLoading] = useState(false);


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(false);
    setDiffrentPasswordError(false);
    setPasswordLengthError(false);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(false);
    setDiffrentPasswordError(false);
    setPasswordLengthError(false);
  };


  const resetForgotPassword = async () => {
    if (password && confirmPassword) {
      setIsLoading(true);
      setDisableBtn(true);
      try {
        const response = await apiCall(API_URL +'reset-forgot-password', {
          user_id:userId,
          password : password,
          password_confirmation: confirmPassword,
        });
        console.log(response.data)
        setDisableBtn(false);
        if(response.data.success == '0'){
          setPasswordChangeSuccsess(true);
          setTimeout(() => {
            navigate('/');     
          }, 2000);
        }else if(response.data.success == '2'){
        setPasswordChangeError(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } 
    
    };

  const handlesetStepThree = () => {
    if (!password || !confirmPassword) {
      setPasswordError(!password); 
      setConfirmPasswordError(!confirmPassword);
      return;
    }
  
    if (password !== confirmPassword) {
      setDiffrentPasswordError(true);
      return;
    } else {
      setDiffrentPasswordError(false); 
    }
  
    if (password.length < 6 || confirmPassword.length < 6) {
      setPasswordLengthError(true);
      return;
    } else {
      setPasswordLengthError(false); 
    }
    resetForgotPassword();
  };


  return (
    <>
      <Helmet>
        <title>Reset a Password | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      <PageBackBtn />

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className="signinup-screen">
            
            <div className="signinup-header">
              <div className="screen-hed">Reset a Password</div>
            </div>

            <form className="signinup-width">
              <div className="row">

              {[0, 1].map((index) => (
                  <div className={`col-12`} key={index}>
                    <div className="signinup-group sgr-icon">
                      <input
                        type={showPasswords[index] ? 'text' : 'password'}
                        placeholder={`${index === 0 ? 'New Password' : 'Confirm Password'}`}
                        value={index === 0 ? password : confirmPassword}
                        onChange={index === 0 ? handlePasswordChange : handleConfirmPasswordChange}
                      />
                      <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)} >
                        {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                      </span>
                      {index === 0 && passwordError && <span className="form-error-msg">Password is required</span>}
                      
                      {index === 1 && confirmPasswordError && <span className="form-error-msg">Confirm passwords is required</span>}

                      {diffrentPasswordError && <span className="form-error-msg">Password and confirm passwords did not matched</span>}
                      {passwordLengthError && <span className="form-error-msg">Minimum 6 charecters required</span>}
                    </div>
                  </div>
                ))}

                <div className="col-12">
                {!isLoading &&<button type="button" onClick={handlesetStepThree} className={`common-submit-btn ${disableBtn ? 'disable' : ''}`} disabled={disableBtn}>Continue</button>}

                 {isLoading && <div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>

                {passwordChangeSuccsess && <FlashMessage type="success" isvisible={true} message="Password changed successfully" />}
                {passwordChangeError && <FlashMessage type="error" isvisible={true} message="Invalid credentials." />}

                <div className="col-12 BackBtnIsShow">
                  <div className="signinup-link-outer-bx slob-fdc fdc-two mb-0">
                    Back to 
                    <Link to="/" className="signinup-link-two">Sign in</Link>
                  </div>
                </div>

              </div>
            </form>

          </div>  
        </div>
      </section>      
      
    </>
  );
};
