import React from 'react'; 
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

export default function DemoBarCharts({ chartData, filterValue, startDate, endDate }) {
    const valueFormatter = (value) => `$${value.toFixed(2)}`;

    const chartSetting = {
        height: 300,
        series: [{ 
            dataKey: 'profit',  
            color: '#0294B5', 
            label: 'Profit', 
            valueFormatter 
        }],
        xAxis: [{
            label: 'Year 2024',  
            scaleType: 'band', 
            dataKey: 'month' 
        }],
        sx: {
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
                transform: 'translateY(8px)',
            },
        },
    };

    const DateDifferenceAndFormat = (start_date, end_date) => {
        const startDateString = start_date;
        const endDateString = end_date;
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const diffInMilliseconds = Math.abs(endDate - startDate);
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        return diffInDays;
    };

    const anotherFormatDate = (dateString) => {
        const date = new Date(dateString);
        if (filterValue === "1day" || filterValue === "1week") {
            return date.toLocaleString("en-US", { month: "long" });
        } else if (filterValue === "custom" && DateDifferenceAndFormat(startDate, endDate) <= 7) {
            return date.toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" });
        } else {
            return date.toLocaleString("en-US", { month: "short" });
        }
    };

    const transformChartData = () => {
        return chartData.map((item) => ({
            profit: Number(item.profit),
            month: anotherFormatDate(item.date_formatted),
        }));
    };

    return (
        <div className='Mycharts'>
            <BarChart
                dataset={transformChartData()}
                {...chartSetting}
            />
        </div>
    );
}
