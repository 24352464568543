import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
//import axios from 'axios';
import FlashMessage from '../../components/FlashMessage';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';

export default function ChangePassword() {
    const { apiCall } = useApi();

    const closePopRef = useRef(null);

    // Otp Js Code Start
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || '';
    const navigate = useNavigate();

    const { dispatch } = useAuth();

    const [otpError, setOtpError] = useState(false);

    const [verificationSuccsess, setVerificationSuccsess] = useState(false);
    const [verificationError, setVerificationError] = useState(false);

    const [verifiedSuccsess, setVerifiedSuccsess] = useState(false);
    const [verifiedError, setVerifiedError] = useState(false);


    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [diffrentPasswordError, setDiffrentPasswordError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);


    const [passwordChangeSuccsess, setPasswordChangeSuccsess] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(false);
    const [timer, setTimer] = useState(30);
    const [startTimer, setStarttimer] = useState(false);


    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
        setDiffrentPasswordError(false);
        setPasswordLengthError(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError(false);
        setDiffrentPasswordError(false);
        setPasswordLengthError(false);
    };

    const sendChangePwdOtp = async () => {
        if (user.id) {
            try {
                const response = await apiCall(API_URL+'send-change-password-otp', {
                    user_id: user.id,
                    token: state?.token,
                },{},navigate);
                setStarttimer(false)
                setTimer(30);
                if (response.data.success == '0') {
                    const receivedOtp = response.data.data.otp.toString();
                    // setOtp(receivedOtp.split(''));
                    setVerificationSuccsess(true);
                    setStarttimer(true);
                } else {
                    setVerificationError(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

    };

    useEffect(() => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;
        if (isUserLoggedIn) {
            setUser(loggedInUser);
            // if (user.id) {
            //     fetchData();
            // }
        }

    }, [state.userData]);


    useEffect(() => {
        if (verificationSuccsess || verificationError || verifiedSuccsess || verifiedError) {
            setTimeout(() => {
                setVerificationSuccsess(false);
                setVerificationError(false);
                setVerifiedSuccsess(false);
                setVerifiedError(false);
            }, 3000);
        }
    }, [verificationSuccsess, verificationError]);


    // Tabing Code Start
    const [steps, setSteps] = useState('step1');
    const handlesetStep = (stepId) => {
        if(stepId == 'step1'){
            sendChangePwdOtp();
        } 
        setSteps(stepId);
    }
    // Tabing Code End



    const verifyOtp = async () => {
        if (user.id && otp) {
            try {
                const otpString = otp.join('');
                const response = await apiCall(API_URL+'verify-change-password-otp', {
                    user_id: user.id,
                    otp: otpString,
                    token:state?.token,
                });
                if (response.data.success == '0') {
                    //setVerifiedSuccsess(true);
                    setTimeout(() => {
                        setSteps('step2');
                    }, 2000);

                } else {
                    setVerifiedError(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            if (!otp) {
                setOtpError(true)
            }
        }

    };



    const handleChangePassword = async () => {
        if (user.id && token && password && confirmPassword && (password == confirmPassword) && password.length >=6 && confirmPassword.length >=6) {
            setPasswordChangeSuccsess(false);
            try {
                const response = await apiCall(API_URL+'change-password', {
                    user_id: user.id,
                    token: token,
                    password:password,
                    password_confirmation:confirmPassword,
                });
                if (response.data.success == '0') {
                    setPasswordChangeSuccsess(true);
                    setOtp(['','','','']);
                    setPassword('');
                    setConfirmPassword('');
                      setTimeout(() => {
                        if (closePopRef.current) {
                            // Trigger click event on the button
                            closePopRef.current.click();
                          }
                         
                      },1000);
                    
                } else {
                    setPasswordChangeError(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            if (!password || !confirmPassword) {
                setPasswordError(!password); 
                setConfirmPasswordError(!confirmPassword);
                return;
              }
              if (password !== confirmPassword) {
                setDiffrentPasswordError(true);
                return;
              }
              if (password.length < 6 || confirmPassword.length < 6) {
                setPasswordLengthError(true);
                return;
              }
            
        }

    };




    const handleChange = (e, index) => {
        const value = e.target.value;

        if (!isNaN(value) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < otp.length - 1 && value !== '') {
                inputRefs[index + 1].current.focus();
            }
        } else if (value === '' && index > 0) {
            // If backspace is pressed and the input field is empty, move focus to the previous input
            inputRefs[index - 1].current.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            if (index > 0) {
                // Move focus to the previous input
                inputRefs[index - 1].current.focus();
            }
            // Clear the current input
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };
    // Otp Js Code End

    // View passwords js code Start
    const [showPasswords, setShowPasswords] = useState([false, false, false]);

    const togglePasswordVisibility = (index) => {
        const updatedShowPasswords = [...showPasswords];
        updatedShowPasswords[index] = !updatedShowPasswords[index];
        setShowPasswords(updatedShowPasswords);
    };
    // View passwords js code End
    // timer code
    useEffect(() => {
        // console.log(startTimer);
        if(startTimer){
          const countdown = setTimeout(() => {
            if (timer > 0) {
              setTimer(timer - 1);
            } else {
              clearTimeout(countdown);
            }
          }, 1000);
        }
      }, [startTimer,timer]);
    return (
        <>
            <div className="common-box mb-4">
                <div className="change-password-bx">
                    <div className="cpb-inner-bx">
                        <div className="cpb-i-lable">Password</div>
                        {/* <div className="cpb-i-date">Last changed: {state.userData.change_passwor_date ? state.userData.change_password_date_formattad : state.userData.updated_at_formattad}</div> */}
                    </div>
                    <div data-bs-target="#ChangePasswordModal" data-bs-toggle="modal" onClick={() => handlesetStep('step1')} className="common-btn-item cbi-fill"><span>Change Password</span></div>
                </div>
            </div>

            {/* --- Change Password Modal Popup --- */}
            <div className="modal fade zoom" id="ChangePasswordModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content mx-auto" style={{ maxWidth: "396px" }}>

                        <div className="custom-modal-header">

                            {steps === "step1" ? (
                                <>
                                    <div className="cmh-lable">Confirmation</div>
                                    <div className="cmh-sub-lable">Please, enter a verification code sent to your email</div>
                                </>
                            ) : steps === "step2" ? (
                                <>
                                    <div className="cmh-lable">Set New Password</div>
                                    <div className="cmh-sub-lable">Create new strong password. After creating new password you will be logged out</div>
                                </>
                            ) : null}

                            <span className="close-icon" data-bs-dismiss="modal" aria-label="Close" ref={closePopRef}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                                </svg>
                            </span>
                        </div>

                        <form className={`custom-modal-data px-3 pb-3 ${steps === 'step1' ? '' : 'd-none'} `} id="step1">
                            <div className="row signinup-group-style">

                                <div className="col-12">
                                    <div className="signinup-group otp-group">
                                        {otp.map((digit, index) => (
                                            <>
                                                <input key={index} inputMode="numeric" type="text" maxLength="1" placeholder=" " value={digit} onChange={(e) => handleChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} ref={inputRefs[index]} />
                                            </>
                                        ))}
                                        {otpError && <span className="form-error-msg">otp is required</span>}
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="otp-resend-bx">
                                        <div>Have Not Received Code</div>
                                        {/* <div><div className="reotp-btn">Resend</div></div> */}
                                        {timer > 0 ? (
                                            <div>Resend In <span>{timer}s</span></div>
                                        ) : (
                                            <div><div className="reotp-btn" onClick={sendChangePwdOtp}>Resend</div></div> 
                                        )}
                                    </div>
                                </div>

                                <div className="col-12">
                                    {/* <div onClick={() => handlesetStep('step2')} className="common-submit-btn">Continue</div> */}
                                    <div onClick={verifyOtp} className="common-submit-btn">Continue</div>

                                    {/* <div className="lsb-loader-btn">
                                    <img src={Img.loading} alt="" />Please wait ....
                                </div> */}
                                </div>

                            </div>
                        </form>

                        {verificationSuccsess && <FlashMessage type="success" isvisible={true} message="Otp sent to your email." />}
                        {verificationError && <FlashMessage type="error" isvisible={true} message="Something went wrong." />}

                        {verifiedSuccsess && <FlashMessage type="success" isvisible={true} message="Verified" />}
                        {verifiedError && <FlashMessage type="error" isvisible={true} message="Otp did not match." />}

                        {passwordChangeSuccsess && <FlashMessage type="success" isvisible={true} message="Password changed successfully" />}
                        {passwordChangeError && <FlashMessage type="error" isvisible={true} message="Invalid credentials." />}
                        
                        <form className={`custom-modal-data px-3 pb-3 ${steps === 'step2' ? '' : 'd-none'} `} id="step2">
                            <div className="row signinup-group-style">

                                {[0, 1].map((index) => (
                                    <div className={`col-12`} key={index}>
                                        <div className={`signinup-group sgr-icon ${index === 0 ? `${passwordError || diffrentPasswordError || passwordLengthError ? "error-group" : "" }` : `${confirmPasswordError || diffrentPasswordError || passwordLengthError ? "error-group" : "" }`}`}>
                                            <input
                                                type={showPasswords[index] ? 'text' : 'password'}
                                                placeholder={`${index === 0 ? 'New Password' : 'Confirm Password'}`}
                                                value={index === 0 ? password : confirmPassword}
                                                onChange={index === 0 ? handlePasswordChange : handleConfirmPasswordChange}
                                            />
                                            <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)} >
                                                {showPasswords[index] ? <Icons.ViewPassSvg /> : <Icons.HidePassSvg/>}
                                            </span>
                                            {index === 0 && passwordError && <span className="form-error-msg">Password is required</span>}

                                            {index === 1 && confirmPasswordError && <span className="form-error-msg">Confirm passwords is required</span>}

                                            {diffrentPasswordError && <span className="form-error-msg">Password and confirm passwords did not matched</span>}
                                            {passwordLengthError && <span className="form-error-msg">Minimum 6 charecters required</span>}
                                        </div>
                                    </div>
                                ))}

                                <div className="col-12">
                                    <button type="button" onClick={handleChangePassword} className="common-submit-btn">Continue</button>

                                    {/* <div className="lsb-loader-btn">
                                    <img src={Img.loading} alt="" />Please wait ....
                                </div> */}
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}
