import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from 'axios';
import FlashMessage from '../../components/FlashMessage';
// Design Files
import PageHeader from './PageHeader';
// import PageBackBtn from './PageBackBtn';
import PageSocialLogin from './test';

// Images Common File
import * as Img from '../../components/Img';

const hidepassSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
    </svg>
  )
}

const viewpassSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  )
}

export default function PageLogin() {

  // View passwords code
  const [showPasswords, setShowPasswords] = useState([false, false, false]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [loginSuccsess,setLoginSuccsess] = useState(false);
  const [loginError,setLoginError] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  
  // email: 'harsh@pmcommu.com',
  // password: '111111',

  const fetchData = async () => {
        setLoginSuccsess(false);
        setLoginError(false);
    if (email && password) {
      setIsLoading(true);
      try {
        const response = await axios.post('https://winprofxcmsbackend.pmcommu.in/api/login', {
          email: email,
          password: password,
        });
        if(response.data.success == '0'){
          setLoginSuccsess(true);
        }else if(response.data.success == '2')
        setLoginError(true);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      if (!email) {
        setEmailError(true)
      } if (!password) {
        setPasswordError(true)
      }
    }

  };





  const togglePasswordVisibility = (index) => {
    const updatedShowPasswords = [...showPasswords];
    updatedShowPasswords[index] = !updatedShowPasswords[index];
    setShowPasswords(updatedShowPasswords);
  };

  return (
    <>
      <Helmet>
        <title>Login | Winpro Fx</title>
      </Helmet>

      <PageHeader />
      {/* <PageBackBtn to="https://winprofx.com/" /> */}

      <section className="lgh-wraper">
        <div className="container-lg cl-custome">
          <div className="signinup-screen">

            {/* <Link to="/register" className="signinup-link" >Not with us? Sign up now!</Link> */}

            <div className="signinup-header">
              <div className="screen-hed">Sign In to Secure CRM</div>
              {/* <div className="screen-pera">to access your account</div> */}
            </div>

            <form className="signinup-width">
              <div className="row">

                <div className="col-12">
                  {/* error-group */}
                  <div className={`signinup-group ${emailError ? 'error-group' : ''}`}>
                    <input value={email} type="text" onChange={(e) => { setEmailError(false); setEmail(e.target.value);}} placeholder="Enter Email or Phone Number" />
                    {/* <div className="group__label">Email</div> */}
                    {/* <div className="group__line"></div> */}
                    {emailError && <span className="form-error-msg">Email is required</span>}
                  </div>
                </div>

                {[0].map((index) => (
                  <div className={`col-12`} key={index}>
                    {/* error-group */}
                    <div className={`signinup-group  sgr-icon ${passwordError ? 'error-group' : ''}`}>
                      <input type={showPasswords[index] ? 'text' : 'password'} value={password} onChange={(e) => { setPasswordError(false); setPassword(e.target.value);}} placeholder="Password" />
                      {/* <div className="group__label">Password</div> */}
                      {/* <div className="group__line"></div> */}
                      <span className={`group_right_icon ${showPasswords[index] ? "active" : ""}`} onClick={() => togglePasswordVisibility(index)} >
                        {showPasswords[index] ? viewpassSvg() : hidepassSvg()}
                      </span>
                      {passwordError&&<span className="form-error-msg">Password is required</span>}
                    </div>
                  </div>
                ))}

                <div className="col-12">
                  <div className="signinup-link-outer-bx">
                    <div className="signinup-link"> </div>
                    {/* <div className="signinup-link" >Scan to login</div> */}
                    <Link to="/restore" className="signinup-link-two" >Forgot Password?</Link>
                  </div>
                </div>

                <div className="col-12">
                  {!isLoading &&<button type="button" onClick={fetchData} className="common-submit-btn">Sign in</button>}

                   {isLoading &&<div className="lsb-loader-btn">
                      <img src={Img.loading} alt="" />Please wait ....
                  </div>}
                </div>
                {loginSuccsess && <FlashMessage type="success" isvisible={true} message="Login successfull" />}
                {loginError && <FlashMessage type="error" isvisible={true} message="Email or password is wrong." />}
                <div className="col-12">
                  <div className="signinup-link-outer-bx slob-fdc mb-0">
                    If you don’t have an account you can
                    <Link to="/register" className="signinup-link-two">Register Here!</Link>
                  </div>
                </div>

              </div>
            </form>

            <PageSocialLogin />



          </div>
        </div>
      </section>

    </>
  );
};
