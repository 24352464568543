import React, { useState, useEffect } from 'react'
import TransactionItem from './TransactionItem';
// Icon Files
import Icons from '../components/icons';
import TransactionDetailModal from './TransactionDetailModal';
import EmptyBox from '../components/EmptyBox';
 
//export default function TransactionItem() {
const TransactionList = ({ transactions = null, loading = true }) => {
  const [show, setShow] = useState(false);
  const [selectedTransaction,setSelectedTransaction] = useState([])
  
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  }

  const handleTransactionSelect = (transactionId) => {
    setSelectedTransaction(transactions.find(transaction => transaction.id === transactionId));
    handleShow()
  }

  // useEffect(() => {
  //   if(transactions && transactions.length > 0){
  //     setSelectedTransaction(transactions[0]);
  //   }
  // },[transactions]);
  
  return (
    <>

      {transactions && transactions.length > 0 &&
        <div className="table-responsive">
          <table className="custom-table ct-hover">
            <thead>
                <tr>
                    <th>Sr</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Account Number</th>
                    {/* <th>Account</th> */}
                    <th>Payment Method</th>
                    <th>Balance</th>
                    <th>Amount</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {transactions && transactions.length > 0 && !loading && transactions.map((transaction, index) => (
              <TransactionItem
                key={index}
                icon={transaction.icon}
                date={transaction.created_at_formattad}
                id={transaction.sequence}
                mt5_number={transaction.transfer_type != 'transfer' ? transaction.user_wallet.account_number : (transaction.user_wallet.account_number?? 'wallet') +' - '+ (transaction.to_user_wallet.account_number ?? 'wallet')}
                // account={transaction.type}
                method={transaction?.payment_method?.name 
                  ? transaction.payment_method.name 
                  : `${(transaction.transfer_type == 'transfer' && transaction.user_wallet.account_number) ? 'mt5' : 'wallet'} - ${(transaction.transfer_type == 'transfer' && transaction.to_user_wallet.account_number) ? 'mt5' : 'wallet'}`
                }
                balance={transaction.computed_balance_usd +" USD"}
                amount={transaction.computed_amount_usd +(transaction.currency ? ' '+ transaction.currency.code : " USD")}
                transfer_type={transaction.transfer_type}
                status={transaction.transaction_status}
                statusColor={(transaction.transaction_status == 'done' || transaction.transaction_status == 'Done') ? 'color-green' :
                  (transaction.transaction_status == 'pending') ? 'color-yellow' :
                  'color-red'}
                onClick={() => handleTransactionSelect(transaction.id)}
              />
            ))}
            </tbody>
          </table>  
        </div>
      }

      {transactions && transactions.length == 0 && !loading &&
        <EmptyBox 
          className="empty-div-bx" 
          eh="No Transactions found" 
          esh="Try adjusting filters to get results."
        /> 
      }
      
      {loading &&
      <>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47"></div>
      </>
      }

      <TransactionDetailModal 
        show={show} 
        handleClose={handleClose} 
        data={selectedTransaction}
      />
    </>
  );
}

export default TransactionList;
