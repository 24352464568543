import React from 'react'

// Design Fils
// import CommonLayout from '../PageCommonLayout/CommonLayout'
import VerificationStatus from '../VerificationStatus'
import VerificationStatus2 from '../VerificationStatus2'
import ProfileInfo from './ProfileInfo'
import ChangePassword from './ChangePassword'
import Breadcrumb from '../../components/Breadcrumb';

export default function PageSettings() {
  return (
    <>

        <Breadcrumb breadcrumbIcon="UserSvg" breadcrumbHeading="Account" />

        <div className="container-lg pt-3 pb-5 px-lg-4">

            <div className="row row-gap-4">
              <div className="col-md-6">
                <ProfileInfo />
              </div>
              <div className="col-md-6">

                <ChangePassword />

                {/* <VerificationStatus /> */}
                <VerificationStatus2 />
              </div>
            </div>
        </div>
    </>
  )
}
