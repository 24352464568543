import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

export default function SummaryChart({ dataset, chartFilter }) {
    // Process the dataset to match the chart format
    
    const getMaxValue = (data) => {
        if (chartFilter === 'tradingVolume' || chartFilter === 'equity') {
            return Math.max(...data.map(item => item.seriesA1));
        } else {
            return Math.max(
                ...data.map(item => Math.max(item.seriesA1, item.seriesA2))
            );
        }
    };
    const processDataset = (trades) => {

        if (chartFilter === 'tradingVolume') {
            const monthlyData = {};

            trades.forEach(trade => {
                const month = new Date(trade.open_date).toLocaleString('default', { month: 'short' });

                if (!monthlyData[month]) {
                    monthlyData[month] = { volume: 0 };
                }
                monthlyData[month].volume += trade.lots || 0;
                
            });

            return Object.keys(monthlyData).map(month => ({
                month,
                seriesA1: monthlyData[month].volume, // Volume values for seriesA1
            }));
        } else if (chartFilter === 'equity') {
            const monthlyData = {};

            trades.forEach(trade => {
                const month = new Date(trade.created_at).toLocaleString('default', { month: 'short' });

                if (!monthlyData[month]) {
                    monthlyData[month] = { volume: 0 };
                }

                monthlyData[month].volume += trade.equity || 0;
                console.log(trade);
                
            });

            return Object.keys(monthlyData).map(month => ({
                month,
                seriesA1: monthlyData[month].volume, // Volume values for seriesA1
            }));
        } else {
            const monthlyData = {};

            trades.forEach(trade => {
                const month = new Date(trade.open_date).toLocaleString('default', { month: 'short' });

                if (!monthlyData[month]) {
                    monthlyData[month] = { profit: 0, loss: 0 };
                }

                monthlyData[month].profit += trade.profit || 0;
                monthlyData[month].loss += trade.loss || 0;
            });

            return Object.keys(monthlyData).map(month => ({
                month,
                seriesA1: monthlyData[month].profit, // Profit values for seriesA1
                seriesA2: monthlyData[month].loss    // Loss values for seriesA2
            }));
        }
    };

    const [chartData, setChartData] = useState([]);
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        if (dataset) {
            const processedData = processDataset(dataset);

            setChartData(processDataset(dataset));
            setMaxValue(getMaxValue(processedData));

        }
    }, [dataset, chartFilter]);

    const valueFormatter = (value) => {
        if (value >= 1000) {
            return `${value / 1000}k`; // Format as 1k, 2k, etc.
        } else {
            return `${value}`; // Default format for values below 1000
        }
    };

    const tooltipFormatter = (data) => {
        return [
            { name: 'Month', value: data.month },
            { name: 'Value', value: valueFormatter(data.value) },
        ];
    };

    const chartSetting = {
        height: 370,
        series: chartFilter === 'tradingVolume' || chartFilter === 'equity' ? [
            { dataKey: 'seriesA1', label: chartFilter === 'tradingVolume' ? 'Trading Volume' : 'Equity', color: '#0294B5' },
        ] : [
            { dataKey: 'seriesA1', stack: 'A', label: 'Profit', color: '#0294B5' },
            { dataKey: 'seriesA2', stack: 'A', label: 'Loss', color: '#060b10' },
        ],
        xAxis: [{
            scaleType: 'band', 
            dataKey: 'month' 
        }],
        yAxis: [{
            valueFormatter,
            min: 0, // Set minimum value for the Y-axis
            max: maxValue + maxValue, // Set the maximum value as the current max + 200
        }],
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
                transform: 'translateY(0px)',
            },
        },
        grid: {
            vertical: {
                strokeDasharray: '1', // Customize the grid lines
            },
            horizontal: {
                strokeDasharray: '1', // Customize the grid lines
            },
        },
    };

    return (
        <div className='Mycharts MC-style-2'>
            <BarChart
                dataset={chartData}
                {...chartSetting}
            />
        </div>
    );
}
