import React from 'react';
import { useParams } from 'react-router-dom';

// Design Fils
// import CommonLayout from '../PageCommonLayout/CommonLayout'
import Breadcrumb from '../../components/Breadcrumb';
import WalletDetail from './WalletDetail';




export default function PageWalletDetail() {
 
  const { id } = useParams();

  return (
    <>
      <Breadcrumb backBtn="bmb-back-btn"  backBtnLink="/wallet"  breadcrumbIcon="EwalletsSvg"  breadcrumbHeading="Bitcoin"  middleLink="/wallet" middleHeading="Wallet"/>

      <div className="container-lg pt-3 pb-5 px-lg-4">

        <div className="row row-gap-4">

            <WalletDetail wallet_id={id}/>

        </div>

      </div>
    </>
  )
}
