// AuthContext.js

import { createContext, useContext, useReducer, useEffect, useState } from 'react';
import FlashMessage from '../components/FlashMessage';


const AuthContext = createContext();

const getLoggedInUserData = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : null;
};

const getToken = () => {
  return localStorage.getItem('token');
};

const initialUser = getLoggedInUserData();
const initialToken = getToken();

const initialState = {
  isLoggedIn: !!initialUser,
  userData: initialUser,
  token: initialToken,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('userData', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      return { isLoggedIn: true, userData: action.payload.user, token: action.payload.token };
    case 'LOGOUT':
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      return { isLoggedIn: false, userData: null, token: null };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [flashMessage, setFlashMessage] = useState(null);
  

  useEffect(() => {
    const loggedInUser = getLoggedInUserData();
    const token = getToken();
    if (loggedInUser) {
      dispatch({ type: 'LOGIN', payload: { user: loggedInUser, token: token } });
    }
  }, []);

  const handleUnauthorized = () => {
    dispatch({ type: 'LOGOUT' });
    setFlashMessage('You have been logged out due to unauthorized access.');
    
  };

  return (
    <AuthContext.Provider value={{ state, dispatch, handleUnauthorized }}>
      {children}
      {flashMessage && <FlashMessage type="error" isvisible={true} message={flashMessage} />}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth, getLoggedInUserData, getToken };
