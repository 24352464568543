import React, { useState, useEffect, useRef } from "react";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
import { Link, useNavigate } from "react-router-dom";

// Images & Icons Files
import accesscard from "../../img/icons/access-card.png";
import Icons from "../../components/icons";

// Design Files
// import CommonLayout from "../PageCommonLayout/CommonLayout";
import Breadcrumb from "../../components/Breadcrumb";
import EmptyBox from "../../components/EmptyBox";
import IBWalletBalances from "./IBWalletBalances";
import DatePickerComponent from "../../components/CommonInput/DatePickerComponent";

// Demo Bar Charts
import DemoBarCharts from "./DemoBarCharts";
import ReportChart from "./ReportChart";
import FilterDropItem from "../../components/Filters/FilterDropItem";

export default function PageIBDashboard() {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || "";
  const isInitialMount = useRef(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(null);
  const [isChartLoading, setIsChartLoading] = useState(null);
  const [isClientChartLoading, setIsClientChartLoading] = useState(null);
  const [isTradeChartLoading, setIsTradeChartLoading] = useState(null);
  const [referralLink, setReferralLink] = useState(null);
  const [userIsIb, setUserIsIb] = useState(false);
  const [userIbRequest, setUserIbRequest] = useState(null);
  const [summary, setSummary] = useState(null);
  const [chartFilter, setChartFilter] = useState("all");
  const [ibChartFilter, setIbChartFilter] = useState("all");
  const [ibTradeChartFilter, setIbTradeChartFilter] = useState("all");
  const [chartData, setChartData] = useState(null);
  const [ibChartData, setIbChartData] = useState(null);
  const [selectFromDate, setSelectFromDateDate] = useState(null);
  const [selectToDate, setSelectToDateDate] = useState(null);
  const [totalDeposit, setTotalDeposit] = useState(null);
  const [totalClient, setTotalClient] = useState(null);
  const [differenceChart, setdifferenceChart] = useState(null);
  const [differenceReport, setdifferenceReport] = useState(null);
  const [ibClient, setIbClient] = useState(false);
  const [ibActiveClient, setIbActiveClient] = useState(false);
  const [ibActiveLots, setIbActiveLots] = useState(false);
  const [ibReward, setIbReward] = useState(false);
  const [totalRegistration, setTotalRegistration] = useState(null);
  const [type, setType] = useState("charts");
  const [client, setClient] = useState("client");
  const [summaryChart, setSummaryChart] = useState(null);
  const [lotsChart, setLotsChart] = useState(null);
  const [activeClient, setActiveClient] = useState(null);
  const [tradeVolume, setTradeVolume] = useState(null);
  const [tradeReword, setTradeReword] = useState(null);
  const [formattedData, setFormattedData] = useState([]);
  const [formattedClientData, setFormattedClientData] = useState([]);
  const [clientDiff, setClientDiff] = useState(null);
  const [lotDiff, setLotDiff] = useState(null);
  const [rewardDiff, setRewardDiff] = useState(null);
  // const [trade, setTrade] = useState();
  const [verificationLoader, setVerificationLoader] = useState(false);
  const [programOption, setProgramOption] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [selectedHeading, setSelectedHeading] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [programData, setProgramData] = useState([]);
  const [isLinkLoader, setIsLinkLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            await fetchVerification();
            // if(loggedInUser.is_ib){
              await fetchIbPrograms();
            //  const transactions = await getReferralLink();
            // }
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error("Failed to fetch wallet group data:", error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
    // getChartData();
    // getIbClientChart();
    // getIbSummeryChartData();
    
  }, [token]);
  const fetchIbPrograms = async () => {
    try {
      setVerificationLoader(true);
      const response = await apiCall(API_URL + "get-ib-programs", {
        token: state?.token,
        user_id:state.userData.id
      });
  
      if (response.data.success == "0") {
        const options = response.data.data;  
        const names = options.map((item) => item.name);
        setProgramOption(names); // Set list of program names
        setProgramData(options); // Set complete program data
        console.log(options[0].id);
        
        if (options.length > 0) {
          setSelectedHeading(options[0].name);
          setSelectedProgramId(options[0].id); 
          setSelectedOption(options[0]);
        }
      }
      setVerificationLoader(false);
    } catch (error) {
      console.error("An error occurred");
      setVerificationLoader(false);
    }
  };
  
  const handleSelectionChange = (selectedOption) => {
    const selectedOption1 = programData.find(
      (program) => program.name === selectedOption
    );
  
    if (selectedOption1) {
      setSelectedHeading(selectedOption1.name);
      setSelectedProgramId(selectedOption1.id);
      setSelectedOption(selectedOption1);
      
    } else {
      console.error("Program not found with the provided name");
    }
  };
  // useEffect(() => {
  //   fetchIbPrograms(); 

  // }, []);
  useEffect(() => {
    if(selectedProgramId){
      getReferralLink();
      getSummary();
      getChartData();
      getIbSummeryChartData();
      getIbClientChart();
      // getIbClientReport();
    }
  }, [selectedProgramId]);

  const getReferralLink = async () => {
    try {
      setIsLinkLoader(true);
      const response = await apiCall(API_URL + "get-referral-link", {
        user_id: state.userData.id,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setReferralLink(response.data.data.referral_link);
        setUserIsIb(response.data.data.is_ib);
        setUserIbRequest(response.data.data.ib_request);
      }
      setIsLinkLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (userIsIb === true) {
      getSummary();
    }
  }, [userIsIb]);
  const getSummary = async () => {
    // Logic to get user wallet group-wise data
    try {
      // setIsLoading(true);
      const response = await apiCall(API_URL + "get-ib-summary", {
        user_id: state.userData.id,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setSummary(response.data.data);
      }
      //setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyReferral = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        //console.log('Deposit address copied to clipboard!');
        // Add user feedback here if desired
      })
      .catch((err) => {
        console.error("Failed to copy the address: ", err);
      });
  };

  // ---
  const [selectDate, setSelectDateDate] = useState(null);
  const handleSelectDateChange = (date) => {
    setSelectDateDate(date);
  };
  // ---
  const handleChartFilter = (filterType) => {
    setChartFilter(filterType);
  };
  const handleIbChartFilter = (filterType) => {
    setIbChartFilter(filterType);
  };
  const handleIbTradeChartFilter = (filterType) => {
    setIbTradeChartFilter(filterType);
  };
  const handleSelectFromDateChange = (date) => {
    setSelectFromDateDate(date);
  };
  const handleSelectToDateChange = (date) => {
    setSelectToDateDate(date);
  };

  useEffect(() => {
    if (chartFilter && chartFilter != "" && chartFilter != "custom") {
      getChartData();
    }
  }, [chartFilter]);
  useEffect(() => {
    if (
      ibActiveClient &&
      ibTradeChartFilter &&
      ibTradeChartFilter != "" &&
      ibTradeChartFilter != "custom"
    ) {
      getIbSummeryChartData();
    }
    if (
      ibActiveLots &&
      ibTradeChartFilter &&
      ibTradeChartFilter != "" &&
      ibTradeChartFilter != "custom"
    ) {
      getIbLots();
    }
    if (
      ibReward &&
      ibTradeChartFilter &&
      ibTradeChartFilter != "" &&
      ibTradeChartFilter != "custom"
    ) {
      getIbReward();
    }
  }, [ibTradeChartFilter]);
  useEffect(() => {
    if (
      !ibClient &&
      ibChartFilter &&
      ibChartFilter != "" &&
      ibChartFilter != "custom"
    ) {
      getIbClientChart();
    }
    if (
      ibClient &&
      ibChartFilter &&
      ibChartFilter != "" &&
      ibChartFilter != "custom"
    ) {
      getIbClientReport();
    }
  }, [ibChartFilter]);

  useEffect(() => {
    if (
      chartFilter &&
      chartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getChartData();
    }
    if (
      !ibClient &&
      ibChartFilter &&
      ibChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbClientChart();
    }
    if (
      ibClient &&
      ibChartFilter &&
      ibChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbClientReport();
    }
    if (
      ibActiveClient &&
      ibTradeChartFilter &&
      ibTradeChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbSummeryChartData();
    }
    if (
      ibActiveClient &&
      ibTradeChartFilter &&
      ibTradeChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbSummeryChartData();
    }
    if (
      ibActiveLots &&
      ibTradeChartFilter &&
      ibTradeChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbLots();
    }
    if (
      ibReward &&
      ibTradeChartFilter &&
      ibTradeChartFilter === "custom" &&
      selectFromDate &&
      selectToDate
    ) {
      getIbReward();
    }
  }, [selectFromDate, selectToDate]);

  const getChartData = async () => {
    // Logic to get user wallet group-wise data
    try {
      setIsChartLoading(true);
      const response = await apiCall(API_URL + "get-client-chart", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: chartFilter,
        token: token,
      });
      if (response.data.success == "0") {
        setChartData(response.data.data.profitData);
      }
      setIsChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getIbClientChart = async () => {
    // Logic to get user wallet group-wise data
    try {
      setType("chart");
      setIbClient(false);
      setIsClientChartLoading(true);
      const response = await apiCall(API_URL + "get-ib-client-chart", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: ibChartFilter,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setIbChartData(response.data.data);
        setTotalDeposit(response.data.data.total_deposit);
        setTotalClient(response.data.data.client_count);
        setdifferenceChart(response.data.data.difference);
      }
      setIsClientChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getIbClientReport = async () => {
    try {
      setIbClient(true);
      setIsClientChartLoading(true);
      setType("report");
      const response = await apiCall(API_URL + "get-ib-client-report", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: ibChartFilter,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setIbChartData(response.data.data);
        setTotalClient(response.data.data.total_registration);
        setdifferenceReport(response.data.data.difference);
      }
      setIsClientChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getIbSummeryChartData = async () => {
    try {
      // setIbClient(true);
      setIbActiveClient(true);
      setIsTradeChartLoading(true);
      setClient("client");
      const response = await apiCall(API_URL + "get-summary-chart", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: ibTradeChartFilter,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setSummaryChart(response.data.data);
        setActiveClient(response.data.data.total_active_clients);
        setTradeVolume(response.data.data.total_client_lots);
        setTradeReword(response.data.data.total_clients_reward);
        setClientDiff(response.data.data.client_diff);
        setLotDiff(response.data.data.lots_diff);
        setRewardDiff(response.data.data.reword_diff);
      }
      setIsTradeChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getIbLots = async () => {
    try {
      setIbActiveLots(true);
      setIbActiveClient(false);
      setIbReward(false);
      setIsTradeChartLoading(true);
      setClient("lots");
      const response = await apiCall(API_URL + "get-summary-chart", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: ibTradeChartFilter,
        program_id:selectedProgramId,
        token: token,
      });
      if (response.data.success == "0") {
        setSummaryChart(response.data.data);
        setActiveClient(response.data.data.total_active_clients);
        setTradeVolume(response.data.data.total_client_lots);
        setTradeReword(response.data.data.total_clients_reward);
        setClientDiff(response.data.data.client_diff);
        setLotDiff(response.data.data.lots_diff);
        setRewardDiff(response.data.data.reword_diff);
      }
      setIsTradeChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getIbReward = async () => {
    try {
      setIbActiveLots(false);
      setIbActiveClient(false);
      setIbReward(true);
      setIsTradeChartLoading(true);
      setClient("reward");
      const response = await apiCall(API_URL + "get-summary-chart", {
        user_id: state.userData.id,
        start_date: selectFromDate,
        end_date: selectToDate,
        chart_filter: ibTradeChartFilter,
        token: token,
        program_id:selectedProgramId,
      });
      if (response.data.success == "0") {
        setSummaryChart(response.data.data);
        setActiveClient(response.data.data.total_active_clients);
        setTradeVolume(response.data.data.total_client_lots);
        setTradeReword(response.data.data.total_clients_reward);
        setClientDiff(response.data.data.client_diff);
        setLotDiff(response.data.data.lots_diff);
        setRewardDiff(response.data.data.reword_diff);
      }
      setIsTradeChartLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (ibChartData) {
      const newFormattedData = formatDataset(ibChartData, type);
      setFormattedData(newFormattedData);
    }
  }, [ibChartData, type]);

  useEffect(() => {
    if (summaryChart) {
      const newFormattedTradeData = formatDataset(summaryChart, client);
      setFormattedClientData(newFormattedTradeData);
    }
  }, [summaryChart, client]);

  const formatDataset = (data, type, ibChartFilter) => {
    const formatDate = (date) => {
      const monthName = date.toLocaleString("default", { month: "short" });
      const yearLabel = date.toLocaleString("default", { year: "numeric" });
      return `${monthName} ${yearLabel}`;
    };

    const formatDateDay = (date) => {
      return date.toLocaleDateString(); // Returns a full date string for "1day" filter
    };

    const formatDateWeek = (date) => {
      const startOfWeek = new Date(
        date.setDate(date.getDate() - date.getDay())
      );
      const endOfWeek = new Date(date.setDate(date.getDate() + 6));
      return `${startOfWeek.toLocaleDateString()} - ${endOfWeek.toLocaleDateString()}`;
    };

    if (type === "chart") {
      if (
        data &&
        data.monthly_deposits &&
        typeof data.monthly_deposits === "object"
      ) {
        return Object.entries(data.monthly_deposits).map(([month, amount]) => {
          const [year, monthNumber] = month.split("-").map(Number);
          const date = new Date(year, monthNumber - 1);

          let formattedDate;
          if (ibChartFilter === "1day") {
            formattedDate = formatDateDay(date);
          } else if (ibChartFilter === "week") {
            formattedDate = formatDateWeek(date);
          } else {
            formattedDate = formatDate(date);
          }

          return {
            month: formattedDate,
            amount: amount !== null ? amount : 0,
          };
        });
      }
    } else if (type === "report") {
      if (
        data &&
        data.monthly_registrations &&
        typeof data.monthly_registrations === "object"
      ) {
        return Object.entries(data.monthly_registrations).map(
          ([month, count]) => {
            const [year, monthNumber] = month.split("-").map(Number);
            const date = new Date(year, monthNumber - 1);
            let formattedDate;
            if (ibChartFilter === "1day") {
              formattedDate = formatDateDay(date);
            } else if (ibChartFilter === "week") {
              formattedDate = formatDateWeek(date);
            } else {
              formattedDate = formatDate(date);
            }
            return {
              month: formattedDate,
              amount: count !== null ? count : 0,
            };
          }
        );
      }
    } else if (type === "client" || type === "lots" || type === "reward") {
      if (
        data &&
        data.monthly_active_clients &&
        typeof data.monthly_active_clients === "object"
      ) {
        return Object.entries(data.monthly_active_clients).map(
          ([month, count]) => {
            const [year, monthNumber] = month.split("-").map(Number);
            const date = new Date(year, monthNumber - 1);

            let formattedDate;
            if (ibTradeChartFilter === "1day") {
              formattedDate = formatDateDay(date);
            } else if (ibTradeChartFilter === "week") {
              formattedDate = formatDateWeek(date);
            } else {
              formattedDate = formatDate(date);
            }

            return {
              month: formattedDate,
              amount: count.active_clients !== null ? count.active_clients : 0,
              tradeVolume: count.trade_volume !== null ? count.trade_volume : 0,
              ibReward: count.ib_reward !== null ? count.ib_reward : 0,
            };
          }
        );
      }
    }

    return [];
  };
  const fetchVerification = async () => {
    try {
      setVerificationLoader(true);
      const response = await apiCall(API_URL + "get-user-verification-level", {
        user_id: state?.userData?.id,
        token: state?.token,
      });
      if (response.data.success == "0") {
        setVerificationLoader(false);
        if (response.data.data.index === "0") {
          setTimeout(() => {
            navigate("/verifications", { state: { verificationErrorMsg: true } });
          }, 1000);
        }
      }
    } catch (error) {
      console.error("An error occurred");
      setVerificationLoader(false);
    }
  };
  
  return (
    <>
      <Breadcrumb
        breadcrumbIcon="DashboardSvg"
        breadcrumbHeading="IB Dashboard"
      />

      <div className="container-lg pt-3 pb-5 px-lg-4">
        <div className="row row-gap-4">
          {userIsIb && userIbRequest &&  !isLoading && (
            <div className="col-xl-12 mx-auto">
              <div className="common-box">
              
                <div className="common-box-heading mb-2">
                  Your referral link
                </div>
                <div className="common-box-sub-heading">
                  This is your referral link. Please feel free to use them to
                  attract clients to our platform.
                </div>

                <div className="referral-bx">
                  {!isLinkLoader && programOption && (
                  <FilterDropItem 
                    className="referral-drop"
                    label={selectedHeading}      // Use selectedHeading to show the selected name
                    groupName="status"
                    options={programOption}       // Provide the program names as options
                    onChange={handleSelectionChange}  // This function will handle changes when a new option is selected
                    selected={selectedHeading}    // The currently selected option (heading)
                  />)}
                  {!isLinkLoader &&  referralLink && (
                    <div className="referral-links-bx">
                      <div className="rlb-link">{referralLink}</div>
                      <div className="rlb-copy-btn" onClick={handleCopyReferral}>
                        <Icons.CopySvg />
                      </div>
                    </div>
                  )}
                  {(isLinkLoader) && (
                  <div className="skeletant-bx skeletant-design sh-47"></div>
                  )}
                </div>
              </div>
            </div>
          )}


          {!userIsIb && !userIbRequest && !isLoading && !verificationLoader && !isChartLoading && !isClientChartLoading && !isTradeChartLoading && (
            <div className="col-xl-12 mx-auto">
              <div className="common-box">
                <EmptyBox
                  className="empty-div-bx py-2"
                  img={accesscard}
                  eh="Apply now for our IB program"
                  esh="Unlock Your Future: Apply Now for Our Introducing Broker Program!"
                  buttonLink="/ib-request"
                  buttonText="IB Request"
                />
              </div>
            </div>
          )}

          {!userIsIb && userIbRequest == "pending" && !isLoading && !verificationLoader && !isChartLoading && !isClientChartLoading && !isTradeChartLoading &&(
            <div className="col-xl-12 mx-auto">
              <div className="common-box">
                <EmptyBox
                  className="empty-div-bx py-2"
                  img={accesscard}
                  eh="You have applied for our IB program"
                  esh="Our manager will get in touch to guide you through further step. When we approve your application, you will get access to your IB Area with a referral link and will be able to manage your IB activity, referrals and payouts. "
                  // buttonLink = "/dashboard"
                  // buttonText = "Go to dashboard"
                />
              </div>
            </div>
          )}

          {userIsIb && !isLoading && (
            <div className="col-xl-12 mx-auto">
              <IBWalletBalances ibBalance={summary?.total_commission_new.toFixed(4)} programId = {selectedProgramId}/>
            </div>
          )}

          {userIsIb && (
          <div className="col-xl-6 mx-auto">
            <div className="common-box">
              <div className="common-box-heading mb-3">Acquisition Report</div>
              <div className="chart-flex-box">
                {/* --- Filter Section Start --- */}
                <div className="filter-bx cfb-filter">
                  <div className="filter-left">
                    <div className="common-tabs-bx ctb-style-2">
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "all" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("all")}
                      >
                        All
                      </div>
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "1day" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("1day")}
                      >
                        1 Day
                      </div>
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "1week" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("1week")}
                      >
                        1 Week
                      </div>
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "1month" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("1month")}
                      >
                        1 Month
                      </div>
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "1year" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("1year")}
                      >
                        1 Year
                      </div>
                      <div
                        className={`ctb-item ${
                          ibChartFilter === "custom" ? "active" : ""
                        }`}
                        onClick={() => handleIbChartFilter("custom")}
                      >
                        Custom
                      </div>
                    </div>
                  </div>
                  {ibChartFilter === "custom" && (
                    <div className="filter-right d-flex column-gap-2">
                      <DatePickerComponent
                        label="From Date"
                        onChange={handleSelectFromDateChange}
                        value={selectFromDate}
                      />
                      <DatePickerComponent
                        label="To Date"
                        onChange={handleSelectToDateChange}
                        value={selectToDate}
                      />
                    </div>
                  )}
                  {/* <div className="input-note-2 mt-0">
                        <Icons.CalendarDaysSvg /> 07 Jul 2014 – 16 Jul 2014
                      </div> */}
                </div>
                {/* --- Filter Section End --- */}

                {/* --- cfb data bx start --- */}
                {!isClientChartLoading && (
                  <>
                    <div className="row row-gap-3">
                      <div className="col-6">
                        <div
                          className={`cfb-data-item ${
                            differenceChart >= 0 ? "cfb-green" : "cfb-red"
                          } ${!ibClient ? "active" : ""}`}
                          onClick={() => {
                            setIbChartFilter("all");
                            getIbClientChart();
                          }}
                        >
                          <div className="cfd-di-label">Deposit</div>
                          <div className="cfd-di-count">
                            {differenceChart >= 0 ? (
                              <Icons.ArrowUpSvg />
                            ) : (
                              <Icons.ArrowDownSvg />
                            )}{" "}
                            {totalDeposit}
                          </div>
                          <div className="cfd-di-tags">
                            <div>{differenceChart}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          className={`cfb-data-item ${
                            differenceReport >= 0 ? "cfb-green" : "cfb-red"
                          } ${ibClient ? "active" : ""}`}
                          onClick={() => {
                            setIbChartFilter("all"); // Set the filter to "all"
                            getIbClientReport(); // Call the function to fetch the report
                          }}
                        >
                          <div className="cfd-di-label">Registration</div>
                          <div className="cfd-di-count">
                            {differenceReport >= 0 ? (
                              <Icons.ArrowUpSvg />
                            ) : (
                              <Icons.ArrowDownSvg />
                            )}{" "}
                            {totalClient ? totalClient : ""}
                          </div>
                          <div className="cfd-di-tags">
                            <div>
                              {differenceReport
                                ? differenceReport >= 0
                                  ? `+` + differenceReport
                                  : differenceReport
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --- cfb data bx end --- */}

                    <ReportChart
                      dataset={ibChartData}
                      // isLoading={isClientChartLoading}
                      type={type}
                      trade={""}
                    />

                    <div className="cfb-hr"></div>

                    <div className="table-responsive cfb-table-responsive common-srollbar">
                      <table className="custom-table ct-small">
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>
                              {type === "chart"
                                ? "Deposit Amount"
                                : "Registration"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formattedData.length > 0 ? (
                            formattedData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
                {/*  */}

                {isClientChartLoading && (
                  <div className="col-12 mx-auto">
                    <div className="skeletant-bx skeletant-design sh-215"></div>
                  </div>
                )}

                {/*  */}
                {/*  */}
              </div>
              
            </div>
          </div>
          )}

          {userIsIb && (
          <div className="col-xl-6 mx-auto">
            <div className="common-box">
              <div className="common-box-heading mb-3">Trading Report</div>
              <div className="chart-flex-box">
                {/* --- Filter Section Start --- */}
                <div className="filter-bx cfb-filter">
                  <div className="filter-left">
                    <div className="common-tabs-bx ctb-style-2">
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "all" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("all")}
                      >
                        All
                      </div>
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "1day" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("1day")}
                      >
                        1 Day
                      </div>
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "1week" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("1week")}
                      >
                        1 Week
                      </div>
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "1month" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("1month")}
                      >
                        1 Month
                      </div>
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "1year" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("1year")}
                      >
                        1 Year
                      </div>
                      <div
                        className={`ctb-item ${
                          ibTradeChartFilter === "custom" ? "active" : ""
                        }`}
                        onClick={() => handleIbTradeChartFilter("custom")}
                      >
                        Custom
                      </div>
                    </div>
                  </div>
                  {ibTradeChartFilter === "custom" && (
                    <div className="filter-right d-flex column-gap-2">
                      <DatePickerComponent
                        label="From Date"
                        onChange={handleSelectFromDateChange}
                        value={selectFromDate}
                      />
                      <DatePickerComponent
                        label="To Date"
                        onChange={handleSelectToDateChange}
                        value={selectToDate}
                      />
                    </div>
                  )}
                  {/* <div className="input-note-2 mt-0">
                      <Icons.CalendarDaysSvg /> 07 Jul 2014 – 16 Jul 2014
                    </div> */}
                </div>
                {/* --- Filter Section End --- */}

                {/* --- cfb data bx start --- */}

                <div className="row row-gap-3 mt-3">
                  <div className="col-6 col-sm-6 col-md-4">
                    <div
                      className={`cfb-data-item cfb-style-2 ${
                        clientDiff >= 0 ? "cfb-green" : "cfb-red"
                      } ${client === "client" ? "active" : ""}`}
                      onClick={() => {
                        // setIbTradeChartFilter("all"); // Set the filter to "all"
                        getIbSummeryChartData(); // Call the function to fetch the report
                      }}
                    >
                      <div className="cfd-di-label">Active Traders</div>
                      <div className="cfd-di-count">
                        {clientDiff >= 0 ? (
                          <Icons.ArrowUpSvg />
                        ) : (
                          <Icons.ArrowDownSvg />
                        )}{" "}
                        {activeClient ? activeClient : ""}
                      </div>
                      <div className="cfd-di-tags">
                        {clientDiff
                          ? clientDiff >= 0
                            ? `+` + clientDiff
                            : clientDiff
                          : 0}
                        {/* <div>0.00%</div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-4">
                    <div
                      className={`cfb-data-item cfb-style-2 ${
                        lotDiff >= 0 ? "cfb-green" : "cfb-red"
                      } ${client === "lots" ? "active" : ""}`}
                      onClick={() => {
                        // setIbTradeChartFilter("all");
                        getIbLots();
                      }}
                    >
                      <div className="cfd-di-label">Trading Volume</div>
                      <div className="cfd-di-count">
                        {lotDiff >= 0 ? (
                          <Icons.ArrowUpSvg />
                        ) : (
                          <Icons.ArrowDownSvg />
                        )}{" "}
                        {tradeVolume ? tradeVolume.toFixed(4) : ""}
                      </div>
                      <div className="cfd-di-tags">
                        {lotDiff ? (lotDiff >= 0 ? `+` + lotDiff.toFixed(4) : lotDiff.toFixed(4)) : 0}
                        {/* <div>0.00%</div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className={`cfb-data-item cfb-style-2 ${
                        rewardDiff >= 0 ? "cfb-green" : "cfb-red"
                      } ${client === "reward" ? "active" : ""}`}
                      onClick={() => {
                        // setIbTradeChartFilter("all");
                        getIbReward();
                      }}
                    >
                      <div className="cfd-di-label">Reward Amount</div>
                      <div className="cfd-di-count">
                        {rewardDiff >= 0 ? (
                          <Icons.ArrowUpSvg />
                        ) : (
                          <Icons.ArrowDownSvg />
                        )}{" "}
                        {tradeReword ? tradeReword.toFixed(4) : ""}
                      </div>
                      <div className="cfd-di-tags">
                        {rewardDiff
                          ? rewardDiff >= 0
                            ? `+` + rewardDiff.toFixed(4)
                            : rewardDiff.toFixed(4)
                          : 0}
                        {/* <div>0.00%</div> */}
                      </div>
                      {/* <div className="cfd-di-count">
                            <Icons.ArrowDownSvg /> {tradeReword}
                          </div>
                          <div className="cfd-di-tags"> */}
                      {/* <div>-19</div>
                        <div>0.00%</div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                {/* --- cfb data bx end --- */}

                {/*  */}
                {!isTradeChartLoading && (
                  <>
                    <ReportChart
                      dataset={summaryChart}
                      // isLoading={isChartLoading}
                      type={""}
                      trade={client}
                    />

                    {/*  */}

                    <div className="cfb-hr"></div>

                    {/*  */}

                    <div className="table-responsive cfb-table-responsive common-srollbar">
                      <table className="custom-table ct-small">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Active Traders</th>
                            <th>Trade Volume</th>
                            <th>Ib Reward</th>
                          </tr>
                        </thead>
                        <tbody>
                          {client && formattedClientData.length > 0 ? (
                            formattedClientData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.month}</td>
                                <td>{item.amount}</td>
                                <td>{item.tradeVolume ? item.tradeVolume.toFixed(4) : 0}</td>
                                <td>{item.ibReward ? item.ibReward.toFixed(4) : "0"}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No data available</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
                {/*  */}

                {isTradeChartLoading && (
                  <div className="col-12 mx-auto">
                    <div className="skeletant-bx skeletant-design sh-215"></div>
                  </div>
                )}

                {/* {!isTradeChartLoading && formattedClientData.length === 0 && (
                  <EmptyBox
                    className="empty-div-bx"
                    eh="No data found"
                    esh="Try adjusting filters to get results."
                  />
                )} */}
              </div>
            </div>
          </div>
          )}

          {userIsIb && !isLoading && (
            <div className="col-xl-12 mx-auto">
              <div className="common-box pt-0 overflowhidden">
                {/* <div className="common-box-heading mb-2">Last 12 month report</div> */}
                {/* --- Filter Section Start --- */}
                <div className="filter-bx ib-chart-filter mt-4">
                  <div className="filter-left">
                    <div className="common-tabs-bx ctb-style-2">
                      <div
                        className={`ctb-item ${
                          chartFilter === "all" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("all")}
                      >
                        All
                      </div>
                      <div
                        className={`ctb-item ${
                          chartFilter === "1day" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("1day")}
                      >
                        1 Day
                      </div>
                      <div
                        className={`ctb-item ${
                          chartFilter === "1week" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("1week")}
                      >
                        1 Week
                      </div>
                      <div
                        className={`ctb-item ${
                          chartFilter === "1month" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("1month")}
                      >
                        1 Month
                      </div>
                      <div
                        className={`ctb-item ${
                          chartFilter === "1year" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("1year")}
                      >
                        1 Year
                      </div>
                      <div
                        className={`ctb-item ${
                          chartFilter === "custom" ? "active" : ""
                        }`}
                        onClick={() => handleChartFilter("custom")}
                      >
                        Custom
                      </div>
                    </div>
                  </div>
                  <div className="filter-right d-flex column-gap-2">
                    {chartFilter && chartFilter === "custom" && (
                      <>
                        <DatePickerComponent
                          label="From Date"
                          onChange={handleSelectFromDateChange}
                          value={selectFromDate}
                        />
                        <DatePickerComponent
                          label="To Date"
                          onChange={handleSelectToDateChange}
                          value={selectToDate}
                        />{" "}
                      </>
                    )}
                  </div>
                  {/* <div className="input-note-2 mt-0">
                    <Icons.CalendarDaysSvg /> 07 Jul 2014 – 16 Jul 2014
                  </div> */}
                </div>
                {/* --- Filter Section End --- */}
                {chartData && chartData != "" && !isChartLoading && (
                  <DemoBarCharts
                    chartData={chartData}
                    filterValue={chartFilter}
                    startDate={selectFromDate}
                    endDate={selectToDate}
                  />
                )}
                {isChartLoading && (
                  <div className="col-12 mx-auto">
                    <div className="skeletant-bx skeletant-design sh-215"></div>
                  </div>
                )}

                {chartData && chartData.length == 0 && !isChartLoading && (
                  <EmptyBox
                    className="empty-div-bx"
                    eh="No data found"
                    esh="Try adjusting filters to get results."
                  />
                )}
              </div>
            </div>
          )}

          {userIsIb && !isLoading && (
            <div className="col-xl-12 mx-auto">
              <div className="common-box">
                <div className="common-box-heading mb-2">Account summary</div>
                <Link to="/ib-reports" className="common-box-link">
                  View Reports
                  <Icons.RightSvg />
                </Link>

                <div className="row row-gap-4 mt-3">
                  <div className="col-md-3 col-sm-6 col-6">
                    <Link to="/ib-reports" className="ib-account-summary-item">
                      <div className="iasi-lable">Active clients</div>
                      <div className="iasi-data">{summary?.active_clients}</div>
                    </Link>
                  </div>

                  <div className="col-md-3 col-sm-6 col-6">
                    <div className="ib-account-summary-item">
                      <div className="iasi-lable">Total deposits</div>
                      <div className="iasi-data">
                        ${summary?.total_deposits}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-6">
                    <div className="ib-account-summary-item">
                      <div className="iasi-lable">Traded volume</div>
                      <div className="iasi-data">
                        {summary? summary?.total_traded_volume.toFixed(4) : 0 }
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6 col-6">
                    <div className="ib-account-summary-item">
                      <div className="iasi-lable">IB reward</div>
                      <div className="iasi-data">
                        ${summary?.total_commission_new.toFixed(4)}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="ib-commission-rate">
                      <div className="icr-lable">Commission rate</div>
                      <div className="icr-current">
                        ${summary?.commission_rate}/lot
                      </div>
                      {/* <div className="common-progress-bx my-2">
                        <div className="common-progress-bar">
                          <div
                            className="common-progress-inner"
                            style={{ width: `${summary?.commission_percent}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="icr-indicator">
                        <div>$1/lot</div>
                        <div>$12/lot</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* --- Skeletant Bx Start --- */}
          {isLoading && (
            <div className="col-xl-12 mx-auto">
              <div className="skeletant-bx skeletant-design sh-215"></div>
            </div>
          )}
          {/* --- Skeletant Bx End --- */}
        </div>
      </div>
    </>
  );
}
