import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage'; 
import { Link, useNavigate } from "react-router-dom";
// Images Common File
import * as Img from '../../components/Img'
import transfer from "../../img/icons/paymentmethod/transfer.png"

// Icons File
import Icons from '../../components/icons'

// Design Files
import Breadcrumb from '../../components/Breadcrumb'
import PaymentMethodCard from './PaymentMethodCard'
import Input from '../../components/CommonInput/Input'
import AccountDropItem from './AccountDropItem'

// AccountDropItem demo img
import walletIcon from "../../img/icons/paymentmethod/wallet.png";
import mt5 from "../../img/icons/paymentmethod/mt5.webp";


export default function Transfer() {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const [wallet, setWallet] = useState([]);
  const [tabType, setTabType] = useState(null);
  const isInitialMount = useRef(true);
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  //transfer fields
  const [type, setType]= useState('wallet');
  const [transactionType, setTransactionType] = useState('crypto');
  const [transferType, setTransferType] = useState('transfer');
  const [currencyId, setCurrencyId] = useState(null);
  const [userWalletId, setUserWalletId] = useState(null);
  const [amount, setAmount] = useState(null);
  //for transfer to another user account
  const [toUserWalletId, setToUserWalletId] = useState(null);
  const [toUserId, setToUserId] = useState(null);
  const [mt5Accounts, setMt5Accounts] = useState(null);
  const [toMt5Account, setToMt5Account] = useState(null);
  const [fromMt5Account, setFromMt5Account] = useState(null);
  
  const [selectedFromMt5Account, setSelectedFromMt5Account] = useState(null);
  const [selectedMt5Account, setSelectedMt5Account] = useState(null);
 

  useEffect(() => {
    const fetchMainWallet = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            await fetchVerification();
            const wallet = await getUserWallet();
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      
      fetchMainWallet();
      getAccounts();
    }
  }, [token]);

  const getUserWallet = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-user-wallet', {
          user_id: state.userData.id,
          token: token,
      });
      if (response.data.success == '0') {
        setWallet(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setUserWalletId(wallet.id);
  }, [wallet]);

  // const getMt5Accounts = async() => {
  //   try {
  //     const response = await apiCall(API_URL +'get-mt5-accounts', {
  //         user_id: state.userData.id,
  //         token: token,
  //         mt5_type:'real'
  //     });
  //     if (response.data.success == '0') {
  //       setMt5Accounts(response.data.data);
  //     }
  //   } catch (error) {
  //       console.error('Error fetching data:', error);
  //   }
  // }

  const getAccounts = async() => {
    try {
      const response = await apiCall(API_URL +'get-user-all-wallets', {
          user_id: state.userData.id,
          token: token,
          mt5_not_type:'demo'
      });
      if (response.data.success == '0') {
        setMt5Accounts(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  const handleMt5AccountChange = (account) => {
    setToMt5Account(account);
  }
  const handleFromMt5AccountChange = (account) => {
    setFromMt5Account(account);
  }
  
  useEffect(() => {
    if(toMt5Account !== null){
      setSelectedMt5Account(mt5Accounts.find(wallet => wallet.id === toMt5Account));
    }
  }, [toMt5Account]);

  useEffect(() => {
    if(fromMt5Account !== null){
      setSelectedFromMt5Account(mt5Accounts.find(wallet => wallet.id === fromMt5Account));
    }
  }, [fromMt5Account]);

  const handleMethodClick = (type) => {
    setTabType(type);
    setAmount('');
    setToUserId('');
    handleStepTabClick('step2');
    setErrors((prev) => ({ ...prev, amount: '' }));
  }

  const handleAmountChange = (value) => {
    // setAmount(value);
    // setErrors((prev) => ({ ...prev, amount: '' })); 
    if(value.length <= 10){      
      setAmount(value);
      setErrors((prev) => ({ ...prev, amount: '' })); 
    }
  };

  const handleToUserIdChange = (value) => {
    setToUserId(value);
    setErrors((prev) => ({ ...prev, to_user_id: '' })); 
  };

  


  const validateFields = () => {
    const newErrors = {};
    if (!amount) {
      newErrors.amount = 'Amount is required';
    } else if (isNaN(amount) || amount <= 0) {
      newErrors.amount = 'Amount must be a positive number';
    } else if (amount.toString().length > 10) {
      newErrors.amount = 'Amount must be up to 10 digits';
    }
    if (!toUserId && tabType === '2')  {
      newErrors.to_user_id = 'Client Id is required';
    }
    if (!fromMt5Account && tabType === '1')  {
      newErrors.from_mt5_account = 'From account is required';
    }
    if (!toMt5Account && tabType === '1')  {
      newErrors.to_mt5_account = 'To account is required';
    }

    //crypto erros
    
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFields();
        if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('type',type);
      formData.append('transaction_type',transactionType);
      formData.append('transfer_type',transferType);
      formData.append('user_wallet_id',fromMt5Account);
      formData.append('to_user_id',toUserId);
      formData.append('mt5_account_id',toMt5Account);
      formData.append('currency_id',currencyId);
      formData.append('amount',amount);
      formData.append('tab_type',tabType);
      formData.append('payment_method_id',null);
      
       try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'send-transfer-request', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('response',response);
          
            if(response.data.success == '0'){
              setTransactionType('crypto')
              setAmount('');
              setToUserId('');
              setRequestSubmitSuccsess(true)
              
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  // --- Payment section Tabbing Js Start
  const [stepTab, setStepTab] = useState('step1');

  const handleStepTabClick = (stepTabId) => {
    setStepTab(stepTabId);
    document.getElementsByClassName('account-content-area')[0].scrollTo(0, 0);
  };
  // --- Payment section Tabbing Js End


  // --- AccountDropItem 
  const options = [
    { img: walletIcon, heading: "My wallet", selected: true },
    // { img: mt5, heading: "#12587456215" },
    // { img: mt5, heading: "#58755255455" }
  ];
  const options2 = [
    // { img: walletIcon, heading: "My wallet", selected: true },
    // { img: mt5, heading: "#12587456215" },
    // { img: mt5, heading: "#58755255455" }
  ];
  // --- AccountDropItem
  const [verificationLoader, setVerificationLoader] = useState(false);
  const fetchVerification = async () => {
    try {
      setVerificationLoader(true);
      const response = await apiCall(API_URL + "get-user-verification-level", {
        user_id: state?.userData?.id,
        token: state?.token,
      });
      if (response.data.success == "0") {
        if (response.data.data.index === "0") {
          setTimeout(() => {
            navigate("/verifications", { state: { verificationErrorMsg: true } });
          }, 1000);
        }
      }
    } catch (error) {
      console.error("An error occurred");
      setVerificationLoader(false);
    }
  };
 

  return (
    <>

    {stepTab === 'step1' ? ( 
    <Breadcrumb breadcrumbIcon="TransactionsSvg" breadcrumbHeading="Transfer" />
    ) : stepTab === 'step2' ? (
    <Breadcrumb onClick={() => handleStepTabClick('step1')} backBtn="bmb-back-btn" breadcrumbIcon="TransactionsSvg" breadcrumbHeading="Transfer" />
    ) : null }

    <div className="container-lg pt-3 pb-5 px-lg-4">

      <div className="row row-gap-4">

        {/* --- Payment Methods Section Step 1 Start --- */}
        <div className={`col-lg-12 ${stepTab === 'step1' ? '' : 'd-none'} `} id="step1">
          <div className="common-box cb-p-bg-none">
            <div className="common-box-heading">All Transfer methods</div>
            <div className="row row-gap-4">

              {/* {paymentMethods.length == 0 && 
                  <> */}
                  {/* --- Skeletant Bx Start --- */}
                  {/* <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div>
  
                  <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div>
  
                  <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div>
  
                  <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div>
  
                  <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div>
  
                  <div className="col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-171">
                      </div>
                  </div> */}
                  {/* --- Skeletant Bx End --- */}
                  {/* </>
              } */}

              <PaymentMethodCard
                imgSrc={transfer}
                label="Between your accounts"
                isUnavailable={false}
                processingTime="Instant - 1 Day"
                fee="0%"
                limits="Minimum Transfer: $10"
                onClick={() => handleMethodClick('1')}
              />

              {/* <PaymentMethodCard
                imgSrc={transfer}
                label="Transfer another account"
                isUnavailable={false}
                processingTime="Instant - 1 Day"
                fee="0%"
                limits="10 - 1,000 USD"
                onClick={() => handleMethodClick('2')}
              /> */}

            </div>
          </div>
        </div>
        {/* --- Payment Methods Section Step 1 End --- */}


        {/* --- Payment Form Section Step 2 Start --- */}
        <div className={`col-xl-8 col-12 mx-auto ${stepTab === 'step2' ? '' : 'd-none'} `} id="step2">

          <div className="common-box">

            <div className="common-box-heading">Fill your transfer details</div>
            {/* <div className="common-box-sub-heading mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}

              <div className={`row signinup-group-style sg-fem-style`}>
                <div className={`col-sm-6 ${tabType == 2 ? 'd-none':''}`}>
                  <AccountDropItem
                      className={errors.from_mt5_account ? "error-group" : ""}
                      label="From Account"
                      img={walletIcon}
                      heading={`${selectedFromMt5Account ? selectedFromMt5Account.account == 'mt5' ? selectedFromMt5Account.account_number: 'Wallet' : "Select Account" }`}
                      options={mt5Accounts}
                      onChange={handleFromMt5AccountChange}
                      selected={selectedFromMt5Account}
                  />
                  {errors.from_mt5_account && <span className="form-error-msg">{errors.from_mt5_account}</span>}
                </div>

                <div className={`col-sm-6 ${tabType == 2 ? 'd-none':''}`}>
                  <AccountDropItem
                      className={errors.to_mt5_account ? "error-group" : ""}
                      label="To Account"
                      img={mt5}
                      heading={`${selectedMt5Account ? selectedMt5Account.account == 'mt5' ? selectedMt5Account.account_number: 'Wallet' : "Select Account" }`}
                      options={mt5Accounts}
                      onChange={handleMt5AccountChange}
                      selected={selectedMt5Account}
                  />
                  {errors.to_mt5_account && <span className="form-error-msg">{errors.to_mt5_account}</span>}
                </div>

                <div className={`col-sm-12 ${tabType == 1 ? 'd-none':''}`}>
                  <Input className={`sgl-icon ${errors.to_user_id ? "error-group" : ""}`} heading="Client ID" type="text" placeholder="Enter Client ID" value={toUserId} onChange={handleToUserIdChange}/>
                  {errors.to_user_id && <span className="form-error-msg">{errors.to_user_id}</span>}
                </div>

                <div className={`col-sm-6 d-none`}>
                  <Input heading="Recipient Wallet ID" className="sgr-icon"  type="number" placeholder="Recipient Wallet ID" />
                </div>

                <div className="col-12">
                  <Input className={`sgl-icon ${errors.amount ? "error-group" : ""}`} heading="Amount" sgl="USD"  type="number" placeholder="Enter Withdraw Amount" value={amount} onChange={handleAmountChange} />
                  {errors.amount && <span className="form-error-msg">{errors.amount}</span>}
                </div>

                {type == 1 && <div className="col-12">
                  <div className="input-note-2 mt-1"><Icons.InfoSvg /> Transfer can only be carried out between accounts of the same currency. Only those balances where this is possible will be shown here</div>
                </div>
                }

                {type == 2 && <div className="col-12">
                  <div className="input-note-2 mt-1"><Icons.InfoSvg /> Please check the correctness of the data entered email / client ID and account ID</div>
                </div> }
              </div>

          </div>

          <div className="mt-4">
            {!isLoading && <div className="common-submit-btn" onClick={handleSubmit}>Transfer</div>
            }

            {isLoading && <div className="lsb-loader-btn">
                <img src={Img.loading} alt="" />Please wait ....
              </div>
            }
            {requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message="Transfer successfully" />}
            {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
          </div>

        </div>
        {/* --- Payment Form Section Step 2 End --- */}

      </div>
      
    </div>
    </>
  )
}
