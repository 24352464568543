import React from 'react'

// Design Files
// import CommonLayout from '../PageCommonLayout/CommonLayout'
import MT5 from './MT5';

export default function PageMT5() {

  return (
    <>
      <MT5 />
    </>
  )
}
