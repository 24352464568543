import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import { useAuth, getLoggedInUserData } from "../../context/AuthContext";
//import axios from 'axios';
import API_URL from "../../config/config";
import useApi from "../../utility/apiCall";

import FlashMessage from "../../components/FlashMessage";

// Images Common File
import * as Img from "../../components/Img";

// Icon Files
import Icons from "../../components/icons";

// Design File
// import CommonLayout from "../PageCommonLayout/CommonLayout";
import Breadcrumb from "../../components/Breadcrumb";

export default function PageverificationsForm() {
  const { apiCall } = useApi();
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const { state } = useAuth();
  const { type } = useParams();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [verifiedDocuments, setverifiedDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [docUploadSuccsess, setDocUploadSuccsess] = useState(false);
  const [docUploadError, setDocUploadError] = useState(false);
  const [docUploadErrorMsg, setDocUploadErrorMsg] = useState("");
  const [selectedDocumentGroupIndex, setSelectedDocumentGroupIndex] = useState(
    []
  );
  const [selfieProofFiles, setSelfieProofFiles] = useState([]);

  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false);
  const [showDocLoader, setShowDocLoader] = useState(false);
  //const [selectedFilesIndex, setSelectedFilesIndex] = useState([]);
  const isInitialMount = useRef(true);
  const [countFile, setCountFile] = useState([]);
  const [countFileMsg, setCountFileMsg] = useState(null);
  const [countFileMsgIndex, setCountFileMsgIndex] = useState(null);

  useEffect(() => {
    getDocumentRequestStatus();
  }, []);
  useEffect(() => {
    if (isInitialMount.current) {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      if (isUserLoggedIn) {
        setUserLoggedIn(true);
        setUser(loggedInUser);
      }
      fetchVerifiedDocumentsListings();
    }
  }, [state.userData]);

  useEffect(() => {
    const counts = selectedDocumentGroupIndex.map((group) => group?.max_files || Infinity);
    setCountFile(counts);
  }, [selectedDocumentGroupIndex]);
  // useEffect(() => {
  //   if();
  // }, [countFile]);


  const getDocumentRequestStatus = async () => {
    setShowDocLoader(true);
    try {
      const response = await apiCall(
        API_URL + "get-document-request-status",
        {
          user_id: state?.userData?.id,
          token: state?.token,
          type:type
        }
      );
      if (response.data.success == "0") {
        if(response.data.data && (response.data.data.status === "pending" || response.data.data.status === "approved"))
        {
          navigate('/verifications',{ state: { showErrorMsg: true } })
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchVerifiedDocumentsListings = async () => {
    setShowDocLoader(true);
    try {
      const response = await apiCall(
        API_URL + "get-document-types-group-wise",
        {
          user_id: state?.userData?.id,
          token: state?.token,
          type:type
        }
      );
      if (response.data.success == "0") {
        setverifiedDocuments(response.data.data);
        setShowDocLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    verifiedDocuments.map((document, index) =>
      setSelectedFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index] = [];
        return newFiles;
      })
    );
  }, [verifiedDocuments]);

  useEffect(() => {
    setAtLeastOneSelected(false);
    selectedFiles.forEach((document, index) => {
      if (document.length > 0) {
        if(verifiedDocuments.length > 1){
          if(selfieProofFiles.length > 0){
            setAtLeastOneSelected(true);
          }
        } else {
          setAtLeastOneSelected(true);
        }
      }
    });
  }, [selectedFiles,selfieProofFiles]);
  
  const handleFileSelect = (e, index) => {
    const maxFiles = selectedDocumentGroupIndex[index]?.max_files || Infinity;
    const newFiles = Array.from(e.target.files);
    const document = verifiedDocuments[index];
  
    // Check if document caption is "Selfie proof"
    if (document.caption === "Selfie proof") {
      setSelfieProofFiles((prevFiles) => {
        const existingFiles = prevFiles || [];
        const totalFiles = existingFiles.length + newFiles.length;
        
        if (totalFiles > maxFiles) {
          setCountFileMsg(`You can only select up to ${maxFiles} file(s).`);
          setCountFileMsgIndex(index);
          e.target.value = ''; // Clear the input
          return prevFiles;
        } else {
          setCountFileMsg(null);
        }
        
        return [...existingFiles, ...newFiles];
      });
    } else {
      // For other documents
      setSelectedFiles((prevFiles) => {
        const existingFiles = prevFiles[index] || [];
        const totalFiles = existingFiles.length + newFiles.length;
        
        if (totalFiles > maxFiles) {
          setCountFileMsg(`You can only select up to ${maxFiles} file(s).`);
          setCountFileMsgIndex(index);
          e.target.value = ''; // Clear the input
          return prevFiles;
        } else {
          setCountFileMsg(null);
        }
        
        const updatedFiles = [...existingFiles, ...newFiles];
        const newFilesState = [...prevFiles];
        newFilesState[index] = updatedFiles;
  
        return newFilesState;
      });
    }
  };
  

  // const deleteFile = (index) => {
  //   const existingFiles = prevFiles[index] || [];
  //   setSelectedFiles((prevFiles) => {
  //     const updatedFiles = [...existingFiles, ...newFiles];
  //     const newFilesState = [...prevFiles];
  //     updatedFiles[index] = [];
  //     return updatedFiles;
  //   });
  // };
  // const handleDragLeave = (e, index) => {
  //   e.preventDefault();
  //   const maxFiles = selectedDocumentGroupIndex[index]?.max_files || Infinity;
  //   const newFiles = Array.from(e.dataTransfer.files);
    
  //   setSelectedFiles((prevFiles) => {
  //     const existingFiles = prevFiles[index] || [];
  //     const totalFiles = existingFiles.length + newFiles.length;
  
  //     if (totalFiles > maxFiles) {
  //       setCountFileMsg(`You can only drop up to ${maxFiles} file(s).`);
  //       return prevFiles;
  //     }
  
  //     const updatedFiles = [...existingFiles, ...newFiles];
  //     const newFilesState = [...prevFiles];
  //     newFilesState[index] = updatedFiles;
  
  //     return newFilesState;
  //   });
  //   e.target.classList.remove("dragover");
  // };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add("dragover");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove("dragover");
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    //setSelectedFiles((prevFiles) => [...prevFiles, ...files]);

    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = files;
      return newFiles;
    });
    e.target.classList.remove("dragover");
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  
  // const deleteFile = (index,vindex) => {
  //   console.log(vindex);
  //   // const updatedFiles = [...selectedFiles];
  //   // updatedFiles.splice(vindex, 1);
  //   // setSelectedFiles(updatedFiles);
  //   setSelectedFiles((prevFiles) => {
  //     // const updatedFiles = [...prevFiles];
  //     // updatedFiles[index] = null; // Set the file at the given index to null
  //     // return updatedFiles;
  //     const updatedFiles = [...prevFiles];
  //     const fileArray = updatedFiles[index] || [];
  //     fileArray.splice(vindex, 1);
  //     updatedFiles[index] = fileArray.length ? fileArray : [];
  //     return updatedFiles;
  //     // const updatedFiles = [...prevFiles];
  //     // updatedFiles[index] = []; // Remove the element at the given index
  //     // return updatedFiles;
  //   });
  // };
  const deleteFile = (index, vindex) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      const fileArray = updatedFiles[index] ? [...updatedFiles[index]] : [];
      
      if (fileArray.length > vindex) {
        fileArray.splice(vindex, 1);
      }
      
      if (fileArray.length > 0) {
        updatedFiles[index] = fileArray;
      } else {
        updatedFiles.splice(index, 1);
      }
  
      return updatedFiles;
    });
  };

  const deleteSelfieProofFile = (vindex) => {
    setSelfieProofFiles((prevFiles) => {
      setAtLeastOneSelected(false);
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(vindex, 1);
      return updatedFiles;
    });
  };

  function formatFileSize(size) {
    const KB = 1024;
    const MB = KB * KB;
    const GB = MB * KB;

    if (size < KB) {
      return `${size} bytes`;
    } else if (size < MB) {
      return `${(size / KB).toFixed(2)} KB`;
    } else if (size < GB) {
      return `${(size / MB).toFixed(2)} MB`;
    } else {
      return `${(size / GB).toFixed(2)} GB`;
    }
  }

  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }
  const handleOptionChange = (e, index, typeIndex) => {
    // Get the selected radio button value
    setSelectedDocumentTypes((prevValues) => [...prevValues, e.target.value]);
    setSelectedDocumentGroupIndex((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = verifiedDocuments[index].document_types[typeIndex];
      return newFiles;
    });
  };

  // const handleSubmit = async () => {
  //   // Create a FormData object
  //   const formData = new FormData();
  //   formData.append("user_id", user?.id);
  //   formData.append("token", state?.token);
  //   // Append each file to FormData
  //   // selectedDocumentTypes.forEach((value, index) => {
  //   //   if (selectedFiles[index].length > 0) {
  //   //     formData.append(`document_type_id[]`, value);
  //   //   }
  //   // });
  //   selectedDocumentTypes.forEach((value, index) => {
  //     if (selectedFiles[index] && selectedFiles[index].length > 0) {
  //       console.log(selectedFiles[index]);
  //       selectedFiles[index].forEach((file, fileIndex) => {
  //         formData.append(`document_type_id[]`, value);
  //         formData.append(`documents[${value}][]`, file);
  //       });
  //     }
  //   });
  //   // selectedFiles.forEach((object, index) => {
  //   //   object.forEach((file, idx) => {
  //   //     formData.append(`documents[]`, file);
  //   //   });
  //   // });

  //   try {
  //     // Send FormData using Axios
  //     setShowLoader(true);
  //     setDocUploadSuccsess(false);
  //     setDocUploadError(false);
  //     apiCall(API_URL + "send-verification-request", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //       .then((response) => {
  //         if (response.data.success == "0") {
  //           setDocUploadSuccsess(true);
  //           setSelectedFiles([]);
  //           setSelectedDocumentTypes([]);
  //           setSelectedDocumentGroupIndex([]);
  //           // navigate('/verifications2')
  //         } else {
  //           setDocUploadError(true);
  //           setDocUploadErrorMsg(response.data.data.error);
  //         }
  //         setShowLoader(false);
  //       })
  //       .catch((error) => {
  //         setShowLoader(false);
  //         setDocUploadError(true);
  //         setDocUploadErrorMsg(error.response.data.message);
  //         console.error("Error uploading profile picture:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //   }
  // };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("user_id", user?.id);
    formData.append("token", state?.token);
    console.log('selectedDocumentTypes',selectedDocumentTypes);
    
    selectedDocumentTypes.forEach((value, index) => {
      // Check if files are present in selectedFiles or if the document is "Selfie proof" and selfieProofFiles is not empty
      if (
        (selectedFiles[index] && selectedFiles[index].length > 0) ||
        (verifiedDocuments[index]?.caption === "Selfie proof" && selfieProofFiles.length > 0)
      ) {
        let filesToUpload = selectedFiles[index] || [];

        // Check if it's the "Selfie proof" document type and add selfieProofFiles if found
        if (verifiedDocuments[index]?.caption === "Selfie proof" && selfieProofFiles.length > 0) {
          // Merge selfieProofFiles with selectedFiles for the "Selfie proof" document type
          filesToUpload = filesToUpload.concat(selfieProofFiles);
        }

        // Now iterate over filesToUpload (the merged array) instead of selectedFiles[index]
        filesToUpload.forEach((file) => {
          formData.append(`document_type_id[]`, value); // Append the document type
          formData.append(`documents[${value}][]`, file); // Append the file
        });
      } else {
        console.log(`No files selected for document type index: ${index}`);
      }
    });


    try {
        setShowLoader(true);
        setDocUploadSuccsess(false);
        setDocUploadError(false);
      console.log('formData', formData);
      
        const response = await apiCall(API_URL + "send-verification-request", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.data.success == "0") {
            setDocUploadSuccsess(true);
            setSelectedFiles([]);
            setSelectedDocumentTypes([]);
            setSelectedDocumentGroupIndex([]);
            navigate('/verifications',{ state: { showSuccessMsg: true } })
        } else {
            setDocUploadError(true);
            setDocUploadErrorMsg(response.data.data.error);
        }
        setShowLoader(false);
    } catch (error) {
        setShowLoader(false);
        setDocUploadError(true);
        setDocUploadErrorMsg(error.response.data.message);
        console.error("Error uploading files:", error);
    }
};

  return (
    <>
      <Breadcrumb
        backBtn="bmb-back-btn"
        backBtnLink="/verifications"
        breadcrumbIcon="DocumentsSvg"
        breadcrumbHeading={type == 'idproof' ? 'Id Proof' : 'Address Proof'}
        middleLink="/verifications"
        middleHeading="Verifications"
      />

      <div className="container-lg pt-3 pb-5 px-lg-4">
        <div className="row justify-content-center row-gap-4">
          {verifiedDocuments.length > 0 &&
            verifiedDocuments.map((document, index) => (
              <div className="col-lg-10" key={index}>
                <div className="common-box">
                  <div className="common-box-heading">
                    {document.caption}{" "}
                    <div className="cbh-count">
                      <span>{index + 1}</span>/
                      <span>{verifiedDocuments.length}</span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap column-gap-4 row-gap-2 mt-4 mb-1">
                    {document.document_types.map((documentType, idx) => (
                      <div className="signinup-group-checkmark sgc-radio" key={idx}>
                        <input
                          className="d-none"
                          type="radio"
                          name={`proof${document.id}[]`}
                          id={`proof${documentType.id}`}
                          value={documentType.id}
                          onChange={(e) => handleOptionChange(e, index, idx)}
                        />
                        <label
                          className="checkmark-lable"
                          htmlFor={`proof${documentType.id}`}
                        >
                          <span className="checkmark-icon">
                            <div className="cmi-inner"></div>
                          </span>
                          {documentType.caption == "Selfie with id proof" ? documentType.caption+" (take selfie with uploaded id proof)" :documentType.caption}
                        </label>
                      </div>
                    ))}
                  </div>
            
                  <div className="row row-gap-3">
                    {/* Selfi proof handling */}
                    {document.caption === "Selfie proof" ? (
                      <>
                        {/* Upload new Selfi proof files if not maxed out */}
                        {selfieProofFiles.length < selectedDocumentGroupIndex[index]?.max_files && (
                          <div className="col-12">
                            <label
                              htmlFor={`SelfieProofFileUpload${index}`}
                              className="fileupload"
                              onDrop={(e) => handleDrop(e, index, true)} // `true` indicates "Selfie proof"
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onMouseOut={handleDragLeave}
                            >
                              <Icons.UploadSvg />
                              <div className="feud-lable">
                                Drag and drop your document(s) or <span>Browse</span>
                              </div>
                              <div className="feud-sub-lable">
                                JPEG, PNG, PDF
                                {selectedDocumentGroupIndex[index]?.max_files
                                  ? `. Max ${selectedDocumentGroupIndex[index]?.max_files} file(s)`
                                  : ""}{" "}
                                · 3MB per file
                              </div>
                            </label>
                            <input
                              hidden
                              type="file"
                              name="selfieProof[]"
                              id={`SelfieProofFileUpload${index}`}
                              accept="image/*"
                              onChange={(e) => handleFileSelect(e, index, true)}
                              multiple={selectedDocumentGroupIndex[index]?.max_files > 1}
                            />
                          </div>
                        )}
            
                        {/* Show selected "Selfie proof" files */}
                        {selfieProofFiles.map((file, vindex) => (
                          <div className="col-lg-6 col-12" key={vindex}>
                            <div className="upload-document-item">
                              <div className="udi-img-bx">
                                {(getFileExtension(file.name).toLowerCase() === "jpg" ||
                                  getFileExtension(file.name).toLowerCase() === "png") && (
                                  <img src={URL.createObjectURL(file)} alt="" />
                                )}
                                {getFileExtension(file.name).toLowerCase() === "pdf" && (
                                  <img src={Img.pdf} alt="" />
                                )}
                              </div>
                              <div className="udi-data-bx">
                                <div className="udi-data-name">{file.name}</div>
                                <div className="udi-data-type-size">
                                  {getFileExtension(file.name)}, {formatFileSize(file.size)}
                                </div>
                              </div>
                              <div
                                className="udi-icon-bx udi-delete"
                                onClick={() => deleteSelfieProofFile(vindex)}
                              >
                                <Icons.TrashSvg />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* Upload files for other documents */}
                        {(selectedFiles[index]?.length <
                          selectedDocumentGroupIndex[index]?.max_files ||
                          !selectedFiles[index]) && (
                          <div className="col-12">
                            <label
                              htmlFor={`DocumentFileUpload${index}`}
                              className="fileupload"
                              onDrop={(e) => handleDrop(e, index, false)} // `false` indicates other documents
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onMouseOut={handleDragLeave}
                            >
                              <Icons.UploadSvg />
                              <div className="feud-lable">
                                Drag and drop your document(s) or <span>Browse</span>
                              </div>
                              <div className="feud-sub-lable">
                                JPEG, PNG, PDF
                                {selectedDocumentGroupIndex[index]?.max_files
                                  ? `. Max ${selectedDocumentGroupIndex[index]?.max_files} file(s)`
                                  : ""}{" "}
                                · 3MB per file
                              </div>
                            </label>
                            <input
                              hidden
                              type="file"
                              name="documents[]"
                              id={`DocumentFileUpload${index}`}
                              accept="image/*"
                              onChange={(e) => handleFileSelect(e, index, false)}
                              multiple={selectedDocumentGroupIndex[index]?.max_files > 1}
                            />
                          </div>
                        )}
            
                        {/* Show selected files for other documents */}
                        {selectedFiles[index]?.map((file, vindex) => (
                          <div className="col-lg-6 col-12" key={vindex}>
                            <div className="upload-document-item">
                              <div className="udi-img-bx">
                                {(getFileExtension(file.name).toLowerCase() === "jpg" ||
                                  getFileExtension(file.name).toLowerCase() === "png") && (
                                  <img src={URL.createObjectURL(file)} alt="" />
                                )}
                                {getFileExtension(file.name).toLowerCase() === "pdf" && (
                                  <img src={Img.pdf} alt="" />
                                )}
                              </div>
                              <div className="udi-data-bx">
                                <div className="udi-data-name">{file.name}</div>
                                <div className="udi-data-type-size">
                                  {getFileExtension(file.name)}, {formatFileSize(file.size)}
                                </div>
                              </div>
                              <div
                                className="udi-icon-bx udi-delete"
                                onClick={() => deleteFile(index, vindex)}
                              >
                                <Icons.TrashSvg />
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
            
              // }
            )}

          

          {/* --- Skeletant Bx Start --- */}
          {showDocLoader && (
            <>
              <div className="col-lg-10">
                <div className="skeletant-bx skeletant-design sh-171"></div>
              </div>

              <div className="col-lg-10">
                <div className="skeletant-bx skeletant-design sh-171"></div>
              </div>

              <div className="col-lg-10">
                <div className="skeletant-bx skeletant-design sh-171"></div>
              </div>
            </>
          )}
          {/* --- Skeletant Bx End --- */}

          <div className="col-lg-10">
            {!showLoader &&
              (atLeastOneSelected || (verifiedDocuments.length > 1 && atLeastOneSelected && selfieProofFiles.length > 0 && selectedDocumentTypes.length > 0)) &&
              selectedDocumentTypes.length > 0 && (
                <button
                  type="button"
                  className="common-submit-btn"
                  onClick={handleSubmit}
                >
                  Upgrade
                </button>
              )}

            {!showLoader &&
              ((!atLeastOneSelected) || (verifiedDocuments.length > 1 && !atLeastOneSelected && selfieProofFiles.length == 0 && selectedDocumentTypes.length == 0) || selectedDocumentTypes.length == 0) && (
                <button
                  type="button"
                  className="common-submit-btn"
                  style={{ backgroundColor: "gray", cursor: "not-allowed" }}
                >
                  Upgrade
                </button>
              )}

            {showLoader && (
              <div className="lsb-loader-btn">
                <img src={Img.loading} alt="" />
                Please wait ....
              </div>
            )}
            {docUploadSuccsess && (
              <FlashMessage
                type="success"
                isvisible={true}
                message="Document request sent successfully"
              />
            )}
            {docUploadError && (
              <FlashMessage
                type="error"
                isvisible={true}
                message={docUploadErrorMsg}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
