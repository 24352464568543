import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage'


// Phone Number Contry Code
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

// Images Common File
import * as Img from '../../components/Img';

// Icon Files
import Icons from '../../components/icons';


export default function ProfileInfo() {
    const { apiCall } = useApi();

    // Edit Profile Start
    const [editProfile, setEditProfile] = useState(false);

    const [userLoggedIn, setUserLoggedIn] = useState(false)
    const [user, setUser] = useState('')
    const { state } = useAuth();
    const token = state.token || ''; 
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const { dispatch } = useAuth();

    const [profilePic, setProfilePic] = useState('');
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [countryId,setCountryId] = useState('');
    const [city, setCity] = useState('');
    const [userToken,setUserToken]  = useState('');


    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailValidationError, setEmailValidationError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [countries, setCountries] = useState([])

    const [registerSuccsess, setRegisterSuccsess] = useState(false);
    const [registerError, setRegisterError] = useState(false);
    const [registerErrorMsg, setRegisterErrorMsg] = useState('');
    const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
    const [phoneNumberAllreadyExist, setPhoneNumberAllreadyExist] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [proflePicSuccsess, setProflePicSuccsess] = useState(false);
    const [proflePicRemoveSuccsess, setProflePicRemoveSuccsess] = useState(false);
    const isInitialMount = useRef(true);
    
 

    const fetchCountriesData = async () => {
        try {
            const response = await apiCall(API_URL +'get-countries', {
                nopaginate: "1",
            });
            if (response.data.success == '0') {
                setCountries(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;
            if (isUserLoggedIn) {
                fetchCountriesData();
                setUserLoggedIn(true);
                setUser(loggedInUser);
                setProfilePic(loggedInUser.image || Img.user)
                setUserId(loggedInUser.id || '');
                //setSequence(loggedInUser.sequence || '');
                setFirstName(loggedInUser.first_name || '');
                setLastName(loggedInUser.last_name || '');
                setPhoneNumber(loggedInUser.phone_number || '');
                setEmail(loggedInUser.email || '');
                setCountry(loggedInUser.country_name || '');
                setCountryId(loggedInUser.country || '');
                setCity(loggedInUser.city || '');
                setUserToken(loggedInUser.token || '');
                
            }
        }
    }, [state.userData]);

    const handleEditProfile = () => {
        setEditProfile(true);
    }
    const handleCanselEditProfile = () => {
        setEditProfile(false);
    }
    // editProfile End


   
    const updateProfile = async () => {
        if (firstName && lastName && email && phoneNumber && country.trim() && city ) {
          setDisableBtn(true);
          setIsLoading(true);
          setRegisterError(false);
          setRegisterSuccsess(false);
          try {
            const response = await apiCall(API_URL +'update-profile', {
              user_id:userId,
              token:token,
              first_name : firstName,
              last_name : lastName,
              email: email,
              phone : phoneNumber,
              country : countryId,
              city:city,
              phone_country : 'IN',
            });
            setDisableBtn(false);
            // console.log(response.data.message)  
            // console.log(response.data)
            // if(response.data.message == 'Validation Error.' && response.data.data.email){
            //   setEmailAlreadyExist(true);
            // }
            // else if(response.data.message == 'Validation Error.' && response.data.data.phone){
            //   setPhoneNumberAllreadyExist(true);
            // } else
            if(response.data.success == '0'){
              setRegisterSuccsess(true);
              localStorage.setItem('userData', JSON.stringify(response.data.data));
              localStorage.setItem('token', token);
              dispatch({ type: 'LOGIN', payload: { user: response.data.data, token: token } });
              setIsLoggedIn(true);
            }else if(response.data.success == '2'){
                setRegisterError(true);
                setRegisterErrorMsg(response.data.data.error);
            }else{
              console.log('Invalid inputs.')
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          setIsLoading(false);
          setEditProfile(false);
        }else{
            if (!firstName) {
                setFirstNameError(true)
              }
              if (!lastName) {
                setLastNameError(true)
              }
              if (!email) {
                setEmailError(true)
              }
              
              if (!phoneNumber) {
                setPhoneNumberError(true)
              }if (!country.trim()) {
                setCountryError(true)
              }
              if (!city) {
                setCityError(true)
              }
        }
      
        };
       
  



    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);
        setEmailError(false);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(inputEmail)) {
            setEmailValidationError(true);
        } else {
            setEmailValidationError(false);
        }
    };


    const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    const selectedCountry = countries.find(country => country.id === selectedCountryId);

    setCountry(selectedCountry ? selectedCountry.name : '');
    setCountryId(selectedCountryId);
    setCountryError(false);
};


    const handlePhoneNumberChange = (value) => {
        if (value && value.trim() !== '') {
          setPhoneNumber(value);
          setPhoneNumberError(false);
        } else {
          setPhoneNumberError(true);
        }
      };

    const handleProfilePicChange = (e) => {
        setProflePicSuccsess(false);
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setProfilePic(reader.result);
      
           
            const formData = new FormData();
            formData.append('photo', file);
            formData.append('user_id',userId);
            formData.append('token',token);
           
            apiCall(API_URL +'update-profile-picture', formData, {
            })
            .then(response => {
                if(response.data.success == '0'){
                    setProflePicSuccsess(true);
                    localStorage.setItem('userData', JSON.stringify(response.data.data));
                    localStorage.setItem('token', token);
                    dispatch({ type: 'LOGIN', payload: { user: response.data.data, token: token } });
                  }
            })
            .catch(error => {
              console.error('Error uploading profile picture:', error);
            });
          };
          reader.readAsDataURL(file);
        }
      };
      
      const handleProfilePicRemove = () => {
        setProflePicRemoveSuccsess(false);
        apiCall(API_URL + 'remove-profile-picture', { user_id: userId, token: token })
            .then(response => {
                if (response.data.success == '0') {
                    setProflePicRemoveSuccsess(true);
                    setProfilePic(Img.user); // Update the profilePic to the default image
                    localStorage.setItem('userData', JSON.stringify(response.data.data));
                    localStorage.setItem('token', token);
                    dispatch({ type: 'LOGIN', payload: { user: response.data.data, token: token } });
                }
            })
            .catch(error => {
                console.error('Error removing profile picture:', error);
            });
    };


    return (
        <>

            <div className="mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="signinup-group-profile">

                            <label className="sgp-lable" For="sgp-profile">
                                <img src={profilePic} />
                                <div className="sgp-overlay">
                                    <Icons.PhotoSvg />
                                    Edit
                                </div>
                            </label>

                            <div className="d-flex column-gap-2">
                                <label For="sgp-profile" className="common-btn-item cbi-fill cbi-small"><span><Icons.EditSvg /> Edit</span></label>
                                <div data-bs-target="#RemovePicConfirmationModal" data-bs-toggle="modal" className="common-btn-item cbi-remove cbi-small"><Icons.TrashSvg /> Remove</div>
                            </div>

                            <input type="file" id="sgp-profile" accept="image/*" onChange={handleProfilePicChange}/>
                            {proflePicSuccsess && <FlashMessage type="success" isvisible={true} message="Profile picture Updated successfully" />}
                            {proflePicRemoveSuccsess && <FlashMessage type="success" isvisible={true} message="Profile picture removed successfully" />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="common-box mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="signinup-group-detail-item sgdi-flex sgdi-font-big">
                            <div className="sgdi-lable">User ID</div>
                            <div className="sgdi-data mt-0 common-word-wrap">{userId}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`common-box ${editProfile ? 'd-none' : ''}`}>
                <div className="common-box-heading"><Icons.UserSvg />Profile info</div>

                <div className="row row-gap-4 mt-4">
                    <div className="col-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">First name</div>
                            <div className="sgdi-data">{firstName}</div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">Last name</div>
                            <div className="sgdi-data">{lastName}</div>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">Email Id</div>
                            <div className="sgdi-data">{email}</div>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">Phone Number</div>
                            <div className="sgdi-data">{phoneNumber}</div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">Country</div>
                            <div className="sgdi-data">{country}</div>
                        </div>
                    </div>


                    <div className="col-6">
                        <div className="signinup-group-detail-item">
                            <div className="sgdi-lable">City</div>
                            <div className="sgdi-data">{city}</div>
                        </div>
                    </div>

                </div>
                <div className="col-12 mt-4">
                    <div className="d-flex justify-content-end column-gap-2">
                        <div onClick={handleEditProfile} className="common-btn-item cbi-fill"><span><Icons.EditSvg /> Edit</span></div>
                    </div>
                </div>
            </div>

            <div className={`common-box ${editProfile ? '' : 'd-none'}`}>
                <div className="common-box-heading"><Icons.UserSvg />Edit Profile info</div>

                <form>
                    <div className="row signinup-group-style  mt-4">
                        <div className="col-6">
                            <div className={`signinup-group ${firstNameError ? "error-group" : ""}`}>
                                <input type="text" value={firstName} onChange={(e) => { setFirstNameError(false); setFirstName(e.target.value); }} placeholder="Enter first name" />
                                {/* <div className="group__label">First Name</div> */}
                                {firstNameError && <span className="form-error-msg">First name is required</span>}
                            </div>
                        </div>

                        <div className="col-6">
                            <div className={`signinup-group ${lastNameError ? "error-group" : ""}`}>
                                <input type="text" value={lastName} onChange={(e) => { setLastNameError(false); setLastName(e.target.value); }} placeholder="Enter last name" />
                                {/* <div className="group__label">Last Name</div> */}
                                {lastNameError && <span className="form-error-msg">Last name is required</span>}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group ${emailError || emailValidationError  ? "error-group" : ""}`}>
                                <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter Email" />
                                {/* <div className="group__label">Enter Email</div> */}
                                {emailError && <span className="form-error-msg">Email is required</span>}
                                {emailValidationError && <span className="form-error-msg">Invalid email format</span>}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group sg-country-code ${phoneNumberError ? 'error-group' : ''}`}>
                                <PhoneInput
                                    international
                                    value={phoneNumber}
                                    // countryCallingCodeEditable={false}
                                    defaultCountry="AE"
                                    placeholder="Enter Phone Number"
                                    onChange={handlePhoneNumberChange}
                                />
                                {/* <input type="number" placeholder="Enter Phone Number" /> */}
                                {/* <div className="group__label">Enter Phone Number</div> */}
                                {phoneNumberError && <span className="form-error-msg">Phone number is required</span>}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group sgr-icon sgri-select ${countryError ? 'error-group' : ''}`}>
                                <select name="country" id="country" value={countryId} onChange={handleCountryChange}>
                                <option value="">Select Country</option>
                                {countries && countries.map((item, index) => (
                                    <option key={index} value={item.id} defaultValue={item.id}>{item.name}</option>
                                ))}
                                </select>
                                <span className="group_right_icon">
                                    <Icons.DownSvg />
                                </span>
                                {countryError && <span className="form-error-msg">Country is required</span>}
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className={`signinup-group ${cityError ? 'error-group' : ''}`}>
                                <input type="text" value={city} onChange={(e) => { setCityError(false); setCity(e.target.value); }} placeholder="Enter City" />
                                {/* <div className="group__label">Enter City</div> */}
                                {cityError && <span className="form-error-msg">City is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="d-flex justify-content-end column-gap-2">
                            <div onClick={updateProfile} className="common-btn-item cbi-fill"><span>Save</span></div>
                            <div onClick={handleCanselEditProfile} className="common-btn-item cbi-outline" cbidata={"Level details"}><span>Cancel</span></div>
                        </div>
                    </div>
                </form>
            </div>
            {registerSuccsess && <FlashMessage type="success" isvisible={true} message="Profile Updated successfully" />}
                {registerError && <FlashMessage type="error" isvisible={true} message={registerErrorMsg} />}
                {emailAlreadyExist && <FlashMessage type="error" isvisible={true} message="Email already exist." />}
                {phoneNumberAllreadyExist && <FlashMessage type="error" isvisible={true} message="Phone Number already exist." />}

            {/* --- Remove Pic Confirmation Modal Popup --- */}
            <div className="modal fade zoom" id="RemovePicConfirmationModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content custom-content mx-auto" style={{ maxWidth: "396px" }}>

                        <div className="custom-modal-header">
                            <div className="cmh-lable">Confirmation</div>
                            <div className="cmh-sub-lable">Are you sure you want to delete this avatar?</div>
                        </div>

                        <div className="custom-modal-data px-3 pt-1 pb-4">
                            <div className="d-flex justify-content-center column-gap-2">
                                <div className="common-btn-item cbi-outline" data-bs-dismiss="modal" aria-label="Close"><span>No</span></div>
                                <div onClick={handleProfilePicRemove} className="common-btn-item cbi-fill" data-bs-dismiss="modal" aria-label="Close"><span>Yes</span></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    );
}
