import React, { useState, useEffect, useRef } from "react";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";
import Icons from "../../components/icons";
import FlashMessage from "../../components/FlashMessage";

const SigninupGroupSwitche = ({ isActive, setIsActive, setVarification }) => {
  // Function to toggle active state and call setVarification
  const toggleActive = () => {
    setVarification();
    // setIsActive(!isActive);
  };

  return (
    <div
      className={`signinup-group-switche ${isActive ? "active" : ""}`}
      onClick={toggleActive}
    >
      <div className="sgs-inner"></div>
    </div>
  );
};

export default function Authentication() {
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || "";
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [flashMsg, setFlashMsg] = useState(null);

  useEffect(() => {
    if (state.userData.two_factor_verification == true) {
      setIsActive(true);
    }
  }, [state.userData]);

  const setVarification = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "set-varification", {
        user_id: state.userData.id,
        isActive: isActive,
        token: token,
      });
      if (response.data.success == "0") {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", token);
        dispatch({
          type: "LOGIN",
          payload: { user: response.data.data, token: token },
        });
        setFlashMsg(response.data.message);
        setIsActive(!isActive);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="common-box">
      <div className="common-box-heading">
        <Icons.SecuritySvg />
        Two-factor authentication
      </div>
      <div className="common-box-sub-heading">
        Two-factor Authentication adds an extra layer of security to your
        account by asking for a verification code when you sign in
      </div>

      {/* {!isLoading && ( */}
      <div className="row row-gap-3">
        <div className="col-12">
          <div className="authentication-item">
            <div className="ai-inner-bx">
              <div className="ai-i-heading">Email Confirmation</div>
              <div className="ai-i-text">
                Receive time-sensitive verification codes to your email ID
              </div>
            </div>
            <SigninupGroupSwitche
              isActive={isActive}
              setIsActive={setIsActive}
              setVarification={setVarification}
            />
          {isLoading && (
            <div className="authentication-prossesing">Prossesing...</div>
          )}
          </div>
        </div>
      </div>
      {/* )} */}

      {!isLoading && flashMsg && (
        <FlashMessage type="success" isvisible={true} message={flashMsg} />
      )}
    </div>
  );
}
