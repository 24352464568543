import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useAuth,
  getLoggedInUserData,
  getToken,
} from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";

// Images Common File
import * as Img from "../../components/Img";

// Design Fils
// import CommonLayout from "../PageCommonLayout/CommonLayout";
import Breadcrumb from "../../components/Breadcrumb";
import Notifications from "./Notifications";
import { set } from "date-fns";

export default function PageNotifications() {
  const { account } = useParams();
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || "";

  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [orderBy, setOrderBy] = useState("desc");
  const [orderColumn, setOrderColumn] = useState("created_at");
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // const transactions = await getMt5History();
            // const Trades = await getTradeHistory(type);
          } catch (error) {
            console.error("Failed to fetch wallet group data:", error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
    getNotification();
  }, [token]);

  const getNotification = async (append = false) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + "get-notification", {
        user_id: state.userData.id,
        token: token,
        page: page,
        per_page: perPage,
        order_by: orderBy,
        order_column: orderColumn,
      });
      if (response.data.success == "0") {
        setNotifications((prevNotifications) =>
          append
            ? [...prevNotifications, ...response.data.data.data]
            : response.data.data.data
        );
        setTotalPages(response.data.data.last_page);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (page > 1) {
      getNotification(true);
    }
  }, [page]);
  const handleShowMore = () => {
    setPage((prevPage) => prevPage + 1);
    // setShowMore(false);
    // getNotification(true);
  };

  return (
    <>
      <Breadcrumb
        breadcrumbIcon="NotificationsSvg"
        breadcrumbHeading="Notifications"
      />

      <div className="container-lg pt-3 pb-5 px-lg-4">
        <div className="row row-gap-4">
          <div className="col-12">
            <Notifications notifications={notifications} isLoading={isLoading}/>
          </div>
          {!isLoading && notifications && totalPages !== page ? (
            <div className="col-12">
              <div onClick={handleShowMore} type="button" className="common-submit-btn">
                Show More
              </div>
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </>
  );
}
