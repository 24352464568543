// src/DatePickerComponent.jsx
import React, { useState } from 'react';
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const DatePickerComponent = ({ label, onChange, value, desktopModeMediaQuery, className="" }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  
  const handleOnChange = (value) =>{
    onChange(value);
    setSelectedDate(value)
    
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        className={`signinup-date-group ${value ? "Mui-selected" : ""} ${className}`}
        value={value}
        views={['year', 'month', 'day']}
        onChange={handleOnChange}
        desktopModeMediaQuery={desktopModeMediaQuery}
       // renderInput={(params) => (
        //   <TextField {...params} placeholder="Select a date" />
        // )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
