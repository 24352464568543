import React, { useState } from 'react';
import Icons from '../../components/icons';


export default function FilterDropItem({ className, label, options, groupName, onChange, selected, ascending = false, onSortChange }) {
  const [isFilterDropActive, setIsFilterDropActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selected);
  const [isAscending, setIsAscending] = useState(true);

  // ---
  const toggleSort = () => {
    onSortChange(isAscending ? 'asc': 'desc');
    setIsAscending(!isAscending);
  };

  //  ---
  const handleFilterEnter = () => {
    setIsFilterDropActive(true);
  };

  const handleFilterLeave = () => {
    setIsFilterDropActive(false);
  };

  const handleFilterOpen = () => {
    setIsFilterDropActive(true);
  };

  const handleFilterClose = () => {
    setIsFilterDropActive(false);
  };

  const handleOptionChange = (option) => {
    onChange(option)
    setSelectedOption(option);
    handleFilterClose();
  };
  // ---

  return (
    <>
      <div className={`filter-drop-item ${isFilterDropActive ? 'active' : ''} ${className}`} onMouseLeave={handleFilterLeave}>
          <div className="filter-sort-bx">
              <div className="filter-sort-label" onClick={handleFilterOpen}>
                {selected ? selected : label} <Icons.DownSvg />
              </div>
              {(ascending && 
                <div className="filter-sort-btn" onClick={toggleSort}>
                  {isAscending ? <Icons.BarArrowUpSvg /> : <Icons.BarArrowDownSvg />}
                </div>
              )}
          </div>

          <div className="filter-drop-bx">
              <div className="filter-drop-inner">
                <div className="filter-checkbox-group fcg-flex-row check-icon-hide check-square filter-drop-lable">
                    {options.map((option, index) => (
                    <div key={index}>
                        <input 
                        type="radio" 
                        id={`${groupName}-cb${index}`} 
                        name={groupName} 
                        checked={selected === option}
                        onChange={() => handleOptionChange(option)}
                        />
                        <label onClick={handleFilterClose} htmlFor={`${groupName}-cb${index}`}>
                        <span className="check-icon"></span>
                        {option}
                        </label>
                    </div>
                    ))}
                </div>
              </div>
          </div>
      </div>
    </>
  );
}