import React from 'react'

export default function Textarea({heading, className, placeholder, value, onChange}) {

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <>
            {(heading && <div className="sgr-outer-label">{heading}</div> )}
            <div className={`signinup-group ${className}`}>
                <textarea placeholder={placeholder} value={value} onChange={handleChange}></textarea>
            </div>
        </>
    )
}
