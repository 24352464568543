import React, { useEffect, useState } from 'react';

// Images Common File
import * as Img from './Img';

// Css File
import '../css/flashmessage.css'

function FlashMessage(props) {
    const [isPopupVisible, setPopupVisible] = useState(((props?.type == 'success' && props.isvisible == true) ? true : false));
    const [isPopupVisibleError, setPopupVisibleError] = useState(((props?.type == 'error' && props.isvisible == true) ? true : false));

    useEffect(() => {
        let popupTimer;

        if (isPopupVisible) {
            popupTimer = setTimeout(() => {
                setPopupVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(popupTimer);
        };
    }, [isPopupVisible]);

    useEffect(() => {
        let popupTimerError;

        if (isPopupVisibleError) {
            popupTimerError = setTimeout(() => {
                setPopupVisibleError(false);
            }, 5000);
        }

        return () => {
            clearTimeout(popupTimerError);
        };
    }, [isPopupVisibleError]);

    const handlefmp = () => {
        setPopupVisible(true);
    };

    const handlefmpclose = () => {
        setPopupVisible(false);
    };

    const handlefmpcloseerror = () => {
        setPopupVisibleError(false);
    };

    return (
        <>
            <div className="cmp-btn d-none" onClick={handlefmp}><i className="fa fa-commenting-o" aria-hidden="true"></i></div>

            <div className={`flash-message ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">

                    <span className="msg-close" onClick={handlefmpclose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>

                    <img className="flash-img" src={Img.success} alt="" />
                    <div className="flash-text">
                        {props?.message ? props.message : 'Success...'}
                    </div>
                </div>
                <div className="flash-time-line"></div>
            </div>

            <div className={`flash-message ${isPopupVisibleError ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">

                    <span className="msg-close" onClick={handlefmpcloseerror}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>

                    <img className="flash-img" src={Img.error} alt="" />
                    <div className="flash-text">
                        {props?.message ? props.message : 'Error...'}
                    </div>
                </div>
                <div className="flash-time-line"></div>
            </div>

            {/* <div className={`flash-message ${isPopupVisible ? 'fmp-message' : ''}`}>
                <div className="flash-message-inner">
                    <span className="msg-close" onClick={handlefmpclose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                        </svg>
                    </span>

                    // if Success img
                    <img className="flash-img" src={Img.success} alt="" />

                    // else Error img
                    // <img className="flash-img" src={Img.error} alt="" />

                    <div className="flash-text">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic ipsa nostrum aperiam.
                    </div>
                </div>
                <div className="flash-time-line"></div>
            </div> */}

            <div className={`flash-message-popup ${isPopupVisible ? 'fmp-active' : ''} `} onClick={handlefmpclose}></div>
        </>
    );
}

export default FlashMessage;
