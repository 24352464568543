import React, { useState } from "react";

import { Modal } from 'react-bootstrap';

// Icons File
// import Icons from '../../components/icons'

// Design File
import NotificationItem from "./NotificationItem";
import EmptyBox from '../../components/EmptyBox';

export default function Notifications({ notifications, isLoading }) {

  // ---
  const [isNotification, setIsNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [statusColor, setStatusColor] = useState(null);


  const handleNotificationClose = () => {
    setIsNotification(false);
    setSelectedNotification(null);

  };
  
  const handleNotificationShow = (notification) => {
    var color = '';
    const status = notification && notification.action_data ? (((notification.notification_type == 'ib_request' || notification.notification_type == 'document_request') && notification.action_data) ? notification.action_data.status : notification.action_data.transaction_status) : 'Unknown';
    if(status == 'done' || status == 'approved' || status == 'Done'){
      setStatusColor('color-green');
    } else if(status == 'Unknown'){
      setStatusColor(''); 
    } else{
      setStatusColor('color-red');
    }
    setSelectedNotification(notification);

    setIsNotification(true);
  }

  return (
    <>
    <div className="common-box">
      <div className="row">
        {notifications && notifications.length > 0 && ( notifications.map((notification, index) => {
            return (
              <>
              <NotificationItem
                key={index}
                // className="ni-new"
                heading={notification.heading}
                rejectReason={notification && notification.action_data ? notification.action_data.reject_reason_description : ''}
                text={notification && notification.action_data ? (((notification.notification_type == 'ib_request' || notification.notification_type == 'document_request') && notification.action_data) ? notification.action_data.status : notification.action_data.transaction_status) : ''}
                dateTime={notification.created_at_formattad}
                onClick={() => handleNotificationShow(notification)}
              />
              </>
            );
          })
        )}
        {!notifications && !isLoading && (
          <EmptyBox
          className="empty-div-bx"
          eh="No notifications"
        />
        )}
        {isLoading && (
        <>
            <div className="col-12 mt-4 notification-outer">
              <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
            </div>

            <div className="col-12 notification-outer">
              <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
            </div>

            <div className="col-12 notification-outer">
              <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
            </div>

            <div className="col-12 notification-outer">
              <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
            </div>
          </>
        )}
      </div>
    </div>

    {/* --- Notification Info Modal --- */}
    <Modal className="zoom custom-content" centered show={isNotification} onHide={handleNotificationClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">{selectedNotification ? selectedNotification.heading : 'Unknown'}</div>
          {/* <div className="cmh-sub-lable">Once you get the confirmation, then enter the amount transferred along with the Proof of Transfer</div> */}
          <span className="close-icon" onClick={handleNotificationClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
        <div class="cm-info-item">
          <div class="cmi-inner">
            <div class="cmi-i-info">Status</div>
            <div class={"cmi-i-data "+statusColor}>{selectedNotification && selectedNotification.action_data ? (((selectedNotification.notification_type == 'ib_request' || selectedNotification.notification_type == 'document_request') && selectedNotification.action_data) ? selectedNotification.action_data.status : selectedNotification.action_data.transaction_status) : ''}</div>
            {/* <div class="ai-i-data color-green">Approved</div>
            <div class="ai-i-data color-yellow">Pending</div> */}
          </div>
          <div class="cmi-inner">
            <div class="cmi-i-info">Date</div>
            <div class="cmi-i-data">{selectedNotification ? selectedNotification.created_at_formattad : ''}</div>
          </div>
          {(selectedNotification && selectedNotification.action_data.reject_reason_description) && (
            <div class="cmi-inner cmi-fd-column">
              <div class="cmi-i-info">Reject reason</div>
              <div class="cmi-i-data">{selectedNotification && selectedNotification.action_data.reject_reason_description ? selectedNotification.action_data.reject_reason_description : ''}</div>
            </div>
          )}
        </div>
      </div>
    </Modal>
    </>
  );
}
