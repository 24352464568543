import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import EmptyBox from "../../components/EmptyBox";

export default function MT5AccountChart({
  chartData,
  filterValue,
  startDate,
  endDate,
}) {
  const valueFormatter = (value) => {
    if (value >= 1000) {
      return `$${value / 1000}k`; // Format as 1k, 2k, etc.
    } else {
      return `$${value}`; // Default format for values below 1000
    }
  };

  const DateDifferenceAndFormat = (start_date, end_date) => {
    // Example start and end dates in the specified format
    const startDateString = start_date;
    const endDateString = end_date;

    // Parse strings into Date objects
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = Math.abs(endDate - startDate);

    // Convert milliseconds into other units if needed
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    // Return data or use as needed
    return diffInDays;
  };

  const anotherFormatDate = (dateString) => {
    if (filterValue == "1day" || filterValue == "1week") {
      const date = new Date(dateString);
      const options = {
        // year: "numeric",
        month: "long",
        //day: "numeric",
      };

      return date.toLocaleString("en-US", options);
    } else if (
      filterValue == "custom" &&
      DateDifferenceAndFormat(startDate, endDate) <= 7
    ) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return date.toLocaleString("en-US", options);
    } else {
      const date = new Date(dateString);
      const options = {
        month: "short",
      };

      return date.toLocaleString("en-US", options);
    }
  };

  const tooltipFormatter = (data) => {
    return [
      { name: "Month", value: data.month },
      { name: "Profit", value: `$${data.Profit.toFixed(2)}` },
    ];
  };

  const transformChartData = () => {
    return chartData.map((item) => ({
      Profit: Number(item.profit.toFixed(2)),
      month: anotherFormatDate(item.date_formatted),
    }));
  };

  const chartSetting = {
    height: 370,
    series: [
      {
        dataKey: "Profit",
        color: "#0294B5",
        valueFormatter,
      },
    ],
    xAxis: [
      {
        scaleType: "band",
        dataKey: "month",
      },
    ],
    yAxis: [
      {
        // label: 'Account Balance',
        min: 0,
        max: Math.max(...chartData.map((item) => item.profit), 100),
        valueFormatter,
      },
    ],
    tooltip: {
      formatter: tooltipFormatter,
    },
    sx: {
      [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
        transform: "translateY(8px)",
      },
    },
    grid: {
      vertical: {
        strokeDasharray: "1", // Customize the grid lines
        stroke: "#d2e5f11a",
      },
      horizontal: {
        strokeDasharray: "1", // Customize the grid lines
        stroke: "#d2e5f11a",
      },
    },
  };

  // const dataset = [
  //     { Profit: 0, month: 'Jan' },
  //     { Profit: 3750, month: 'Feb' },
  //     { Profit: 8265, month: 'Mar' },
  //     { Profit: 16570, month: 'Apr' },
  //     { Profit: 18542, month: 'May' },
  //     { Profit: 22485, month: 'June' },
  //     { Profit: 26257, month: 'July' },
  //     // { AccountBalance: "", month: 'Aug' },
  //     // { AccountBalance: "", month: 'Sept' },
  //     // { AccountBalance: "", month: 'Oct' },
  //     // { AccountBalance: "", month: 'Nov' },
  //     // { AccountBalance: "", month: 'Dec' },
  // ];

  return (
    <div className="Mycharts">
      {chartData != "" && chartData.length > 0 && (
        <LineChart dataset={transformChartData()} {...chartSetting} />
      )}
    </div>
  );
}
