import React, { useState, useEffect } from 'react';
import Icons from '../../components/icons';

export default function PanelCounter({ className, placeholder, Label, dropdownItems, tags = [],inputValue, isDrop, isShow, error, errorMeg, onValueChange }) {
    const [isCommonDropActive, setIsCommonDropActive] = useState(false);
    const [panelInputValue, setInputValue] = useState(inputValue);

    useEffect(() => {
        if (Label !== 'lots' && Label !== 'Lots') {
            setInputValue(inputValue);
        }
        onValueChange(panelInputValue);
    }, [panelInputValue, inputValue, Label]);


    const handleCommonLeave = () => {
        setIsCommonDropActive(false);
    };

    const handleCommonClick = () => {
        setIsCommonDropActive(true);
    };

    const handleCommonClose = () => {
        setIsCommonDropActive(false);
    };

    const handleInputChange = (e) => {
        // console.log('parseFloat(e.target.value)', parseFloat(e.target.value));
        setInputValue(parseFloat(e.target.value));
    };

    const handlePlusClick = () => {
        setInputValue((prevValue) => parseFloat((parseFloat(prevValue) + 0.01).toFixed(2)));
    };

    const handleMinusClick = () => {
        if(parseFloat((panelInputValue - 0.01).toFixed(2)) > 0){
            setInputValue((prevValue) => parseFloat((parseFloat(prevValue) - 0.01).toFixed(2)));
        }
    };

    if (!isShow) return null;

    return (
        <>
            {isDrop ? (
                <div className={`common-drop-item panel-drop-bx ${className} ${isCommonDropActive ? 'active' : ''}`} onMouseLeave={handleCommonLeave}>
                    <div className={`panel-counter-bx ${className}`}>
                        <div className="pcb-input-bx">
                            <input type="number" value={panelInputValue} onChange={handleInputChange} placeholder={placeholder} />
                            <div className="pcb-drop-label" onClick={handleCommonClick}>{Label} <Icons.CaretDownSvg /></div>
                        </div>
                        <div className="pcb-btns">
                            <div className="pcb-btn-item pcb-plus" onClick={handlePlusClick}><Icons.PlusSvg /></div>
                            <div className="pcb-btn-item pcb-minus" onClick={handleMinusClick}><Icons.MinusSvg /></div>
                        </div>
                    </div>
                    <div className="common-drop-bx">
                        <div className="common-drop-inner" onClick={handleCommonClose}>
                            {dropdownItems.map((item, index) => (
                                <div key={index} className="panel-drop-item">{item}</div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`panel-counter-bx ${className}`}>
                    <div className="pcb-input-bx">
                        <input type="number" value={panelInputValue} onChange={handleInputChange} placeholder="Not set" />
                        <div className="pcb-label">{Label}</div>
                    </div>
                    <div className="pcb-btns">
                        <div className="pcb-btn-item pcb-plus" onClick={handlePlusClick}><Icons.PlusSvg /></div>
                        <div className="pcb-btn-item pcb-minus" onClick={handleMinusClick}><Icons.MinusSvg /></div>
                    </div>
                </div>
            )}
            {(error && errorMeg && <span className="form-error-msg text-start">{errorMeg}</span>)}

            {tags.length > 0 && (
                <div className="panel-tags">
                    {tags.map((tag, index) => (
                        <div key={index} className="panel-tag-item">{tag}</div>
                    ))}
                </div>
            )}
        </>
    );
}
