import React from 'react'

// Design Fils
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import DeviceManagement from './DeviceManagement';
import Authentication from './Authentication';
// import AntiPhishingCode from './AntiPhishingCode';
// import AccountSecurityLevel from './AccountSecurityLevel';
import Breadcrumb from '../../components/Breadcrumb';

export default function PageSecurity() {
  return (
    <>

    <Breadcrumb breadcrumbIcon="SecuritySvg" breadcrumbHeading="Security" />

    <div className="container-lg pt-3 pb-5 px-lg-4">
        <div className="row row-gap-4">

            <div className="col-lg-12 order-2 order-lg-1">
                <Authentication />
            </div>

            {/* <div className="col-lg-6 order-1 order-lg-2 d-none">
                <AccountSecurityLevel />
                <div className="d-none d-lg-block">
                  <AntiPhishingCode />
                </div>
            </div> */}

            {/* <div className="col-lg-6 d-block d-lg-none order-3">
                <AntiPhishingCode />
            </div> */}

            <div className="col-lg-12 order-4">
                <DeviceManagement />
            </div>

        </div>
    </div>
    </>
  )
}
