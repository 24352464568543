import React from 'react'

// Css File
import "../../css/tradingview.css"

// Design Files
import TradingViewWidget from './TradingViewWidget';

export default function PageTradeView() {

  return (
    <TradingViewWidget />
  )
}
