import React from 'react'
import { useParams } from 'react-router-dom';
// Css File
import "../../css/tradingview.css"

// Design Files
import TradingViewWidget from './TradingViewWidget';

export default function PageTradeView() {
  const { account_id } = useParams();
  return (
    <TradingViewWidget account_id={account_id}/>
  )
}
