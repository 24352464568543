import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from "../../context/AuthContext";
import useApi from "../../utility/apiCall";
import API_URL from "../../config/config";


// Audio & Menu Context File
import { useMenu } from '../../context/MenuContext';

// Images Common File
import * as Img from '../../components/Img';

// Icon Files
import Icons from '../../components/icons';

export default function CommonSideMenu() {
  const { apiCall } = useApi();
  const [user, setUser] = useState("");
  const { state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const isInitialMount = useRef(true);
  const token = state.token || "";
  // const { dispatch } = useAuth();
  // const navigate = useNavigate();

  // --- ---
  const { isActive, toggleMenu, closeMenu } = useMenu();
  const [verifiedCaption,setVerifiedCaption] = useState(null);
  // --- ---

  // const handleLogout = () => {
  //   dispatch({ type: 'LOGOUT'});
  //   navigate('/');
  // }

  useEffect(() => {
    // Set up the interval to call the function every 10 seconds
    const intervalId = fetchUserSteps(); // 10000ms = 10 seconds
  }, []);

  const fetchUserSteps = async () => {
    try {
      const response = await apiCall(
        API_URL + "get-user-profile",
        {
          user_id: state?.userData?.id,
          token: state?.token,
        },
        {}
      );
      if (response.data.success == "0") {
        // console.log('response.data.data',);
        setVerifiedCaption(response.data.data.verified);
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("token", token);
        dispatch({
          type: "LOGIN",
          payload: { user: response.data.data, token: token },
        });
      }
    } catch (error) {
      console.error("An error occurred");
    }
  };

  const menuItems = [
    { isNavLink: true, to: "/dashboard", icon: Icons.DashboardSvg, text: "Dashboard", onClick: closeMenu, checkIfIb:true },
    { isNavLink: true, to: "/mt5", icon: Icons.Mt5Svg, text: "MT5", onClick: closeMenu, checkIfIb:true },
    { isNavLink: true, to: "/wallet", icon: Icons.EwalletsSvg, text: "E-Wallets", onClick: closeMenu, checkIfIb:state?.userData?.is_ib ? true : false },
    { isNavLink: true, to: "/platfrom", icon: Icons.PlatfromSvg, text: "Platfrom", onClick: closeMenu, checkIfIb:true },
    { isNavLink: true, target: true, to: "/trading", icon: Icons.TradeSvg, text: "Winprofx terminal", onClick: closeMenu, checkIfIb:true },
    

    { sectionLabel: "Funds" },
    { isNavLink: true, to: "/deposit", icon: Icons.DepositSvg, text: "Deposit", onClick: closeMenu, checkIfIb:true },
    { isNavLink: true, to: "/withdraw", icon: Icons.WithdrawSvg, text: "Withdraw", onClick: closeMenu, checkIfIb:state?.userData?.verified ? true : false },
    { isNavLink: true, to: "/transfer", icon: Icons.TransactionsSvg, text: "Transfer", onClick: closeMenu, checkIfIb:state?.userData?.verified ? true : false },
    // { isNavLink: false, to: "", icon: Icons.TransactionsSvg, text: "Internal Transfer" },
    { isNavLink: true, to: "/transactions", icon: Icons.TransactionsSvg, text: "Transactions", onClick: closeMenu, checkIfIb:true },

    { sectionLabel: "Analytics" },
    { 
      isDropdown: true, text: "Analytics", icon: Icons.AnalyticsSvg, 
      dropdownItems: [
        { to: "/analytics-summary", icon: Icons.PresentationChartBarSvg, text: "Summary", onClick: closeMenu, checkIfIb:true },
        { to: "/order-history", icon: Icons.NumberedListSvg, text: "History of order", onClick: closeMenu, checkIfIb:true },
      ] 
    },

    { sectionLabel: "Profile" },
    { 
      isDropdown: true, text: "Settings", icon: Icons.Cog6Svg, 
      dropdownItems: [
        { to: "/settings", icon: Icons.UserSvg, text: "Account", onClick: closeMenu, checkIfIb:true },
        { to: "/verifications", icon: Icons.VerificationSvg, text: "Verification", onClick: closeMenu, checkIfIb:true },
        // { to: "/finance", icon: Icons.FinanceSvg, text: "Finance", onClick: closeMenu },
        { to: "/security", icon: Icons.SecuritySvg, text: "Security", onClick: closeMenu, checkIfIb:true },
      ] 
    },
     
      { sectionLabel: "Portal" , sectionLabelClass: state?.userData?.verified ? "" : "d-sm-none"},
      { 
        isDropdown: true, dropdownClass: state?.userData?.verified ? "" : "d-sm-none", text: "IB Room", icon: Icons.UserSvg, 
        dropdownItems: [
          { isNavLink: true, to: "/ib-dashboard", icon: Icons.DashboardSvg, text: "IB Dashboard", onClick: closeMenu, checkIfIb:true },
          { isNavLink: true, to: "/ib-reports", icon: Icons.DocumentsSvg, text: "Reports", onClick: closeMenu, checkIfIb: state?.userData?.is_ib ? true : false },
          // { isNavLink: true, to: "/ib-faqs", icon: Icons.QuestionsSvg, text: "Faqs", onClick: closeMenu, checkIfIb:true },
        ]
      },


      { isNavLink: true, to: "/suggest", icon: Icons.ChatBubbleOvalLeftEllipsisSvg, text: "Suggest a feature", onClick: closeMenu, checkIfIb:true }, 

    // { sectionLabel: "History" },
    // { isNavLink: true, to: "/transactions", icon: Icons.TransactionsSvg, text: "Transactions", onClick: closeMenu, checkIfIb:true },

    { sectionLabel: "Products", sectionLabelClass: "d-sm-none" },
    { 
      isDropdown: true, dropdownClass: "d-sm-none", text: "Winprofx products", icon: Icons.AppsOutlineSvg, 
      dropdownItems: [
        { isNavLink: true, to: "/mt5", icon: Icons.Mt5Svg, text: "Client Desk", onClick: closeMenu, checkIfIb:true },
        { isNavLink: true, target: true, to: "https://winprofx.com/", icon: Icons.LinkSvg, text: "Public website", onClick: closeMenu, checkIfIb:true },
        { isNavLink: true, target: true, to: "https://winprofx.com/introducing-broker", icon: Icons.UsersSvg, text: "Become a Partner", onClick: closeMenu, checkIfIb:true },
      ]
    },

    
    // { sectionLabel: "Other" },
    // { isNavLink: false, to: "##", icon: Icons.NotificationsSvg, text: "Notifications" },
    // { icon: Icons.LogoutSvg, text: "Log Out", onClick: handleLogout },
  ];

  function isAnyInnerUrlActive(dropdownItems) {
    return dropdownItems.some(item => item.to === window.location.pathname);
  }

  return (
    <>
    <aside className={`account-side-menu ${isActive ? 'active' : ''}`}>

      <div className="asm-logo">
          <Link to="/dashboard">
            <img className="asm-main-logo" src={Img.logo_light} alt="" />
            <img className="asm-logo-icon" src={Img.icon_light} alt="" />
          </Link>

          <span style={{top:"10px"}} className="close-icon" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
          </span>
      </div>     

      <div className="account-menu-bx">
        {menuItems.map((item, index) => (
          item.sectionLabel ? (

            <div key={index} className={`amb-section-lable ${item.sectionLabelClass ? item.sectionLabelClass : "" }`}>{item.sectionLabel}</div>

            ) : (
            item.isNavLink && item.checkIfIb == true ? (

              <NavLink key={index} to={item.to} target={item.target ? "_blank" : "_self"} className="amb-link" onClick={item.onClick && item.onClick}>
                <item.icon />
                <span>{item.text}</span>
              </NavLink>

            ) : item.isDropdown ? (

              <div key={index} className={`amb-drop-bx ${item.dropdownClass ? item.dropdownClass : "" }`}>
                <div className="amb-link" data-bs-toggle="collapse" data-bs-target={`#amb-drop-item-${index}`} aria-expanded="false" aria-controls={`amb-drop-item-${index}`}>
                  <item.icon />
                  <span>{item.text} <Icons.DownSvg /></span>
                </div>
                <div className={`collapse amb-drop-item ${isAnyInnerUrlActive(item.dropdownItems) ? 'show' : ''}`} id={`amb-drop-item-${index}`}>
                  {item.dropdownItems.map((dropdownItem, dIndex) => (
                    dropdownItem.checkIfIb == true && (
                    <NavLink key={dIndex} to={dropdownItem.to} className="amb-link" target={dropdownItem.target ? "_blank" : "_self"} onClick={dropdownItem.onClick && dropdownItem.onClick}>
                      <dropdownItem.icon />
                      <span>{dropdownItem.text}</span>
                    </NavLink>
                    )
                  ))}
                </div>
              </div>

            ) : item.checkIfIb == true && (

              <div key={index} className="amb-link" onClick={item.onClick && item.onClick}>
                <item.icon />
                <span>{item.text}</span>
              </div>

            )
          )
        ))}
        
      </div>
      
    </aside>
    
    <div onClick={toggleMenu} className={`side-menu-overlay ${isActive ? 'active' : ''}`}></div>
    </>
  )
}
