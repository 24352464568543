import React, { useState } from 'react'
import HistoryItem from './HistoryItem';

// Icon Files
import Icons from '../components/icons';
import EmptyBox from '../components/EmptyBox';

import empty2 from "../img/icons/empty2.png"
 
const HistroyList = ({ trades = null, orderBy,orderColumn,loading = true }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const handleOrderChange = (sort) =>{
    orderBy(sort);
    setSortBy(sort);
    setSortOrder(orderColumn);
  } 

  

  return (
    <>

      {trades && trades.length > 0 &&
        <div className="table-responsive">
          <table className="custom-table">
            <thead>
                <tr>
                    <th className={sortBy === 'trade_id' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('trade_id')}>Order Id <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'symbol_name' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('symbol_name')}>Symbol <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'side' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('side')}>Side <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'open_date' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('open_date')}>Open Date <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'close_date' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('close_date')}>Close Date <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'lots' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('lots')}>Lots <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'open_price' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('open_price')}>Open Price<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'close_price' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('close_price')}>Close Price<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'take_profit' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('take_profit')}>TP<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'stop_loss' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('stop_loss')}>SL<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                    <th className={sortBy === 'profit' ? 'active '+sortOrder: ''} onClick={()=>handleOrderChange('profit')}>Profit<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                </tr>
            </thead>
            <tbody>
            {trades && trades.length > 0 && !loading && trades.map((trades, index) => (
              <HistoryItem
                key={index}
                id={trades.trade_id}
                symbolImages={trades.symbol ? trades.symbol.symbol_images : "-"}
                symbol={trades.symbol ? trades.symbol.name : "-"}
                side={trades.side}
                open_date={trades.open_date ? trades.open_date_formattad : "-"}
                close_date={trades.close_date ? trades.close_date_formattad : "-"}
                lots={trades.lots}
                price={trades.price}
                open_price={trades.open_price}
                close_price={trades.close_price}
                take_profit={trades.take_profit}
                stop_loss={trades.stop_loss}
                profit={(trades.profit == 0 && trades.loss == 0)
                  ? '-'
                  : trades.profit > 0
                  ? trades.profit.toFixed(5)
                  : "- " + trades.loss.toFixed(5)}
              />
            ))}
            </tbody>
          </table>  
        </div>
      }

      {trades && trades.length == 0 && !loading &&
        <EmptyBox 
          className="empty-div-bx no-invert"
          img={empty2}
          eh="No Trades found" 
          esh="Try adjusting filters to get results."
        /> 
      }
      
      {loading &&
      <>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47 mb-2"></div>
        <div className="skeletant-bx skeletant-design sh-47"></div>
      </>
      }

      {/* <TransactionDetailModal 
        show={show} 
        handleClose={handleClose} 
        data={[]}
      /> */}
    </>
  );
}

export default HistroyList;
