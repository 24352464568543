import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

// Icon Files
import Icons from '../../components/icons';
 
export default function SpecificationAvailability() {
  const { apiCall } = useApi();
  const [user, setUser] = useState('')
  const { state } = useAuth();

  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [verificationLabel,setVerificationLabel] = useState('Level 0');
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      if (isUserLoggedIn) {
          setUserLoggedIn(true);
          setUser(loggedInUser);
      }
      fetchVerification();
    }
  }, [state.userData]);

  const fetchVerification = async () => {
    try {
      
        const response = await apiCall(API_URL + 'get-user-verification-level', {
          user_id: state?.userData?.id,
          token: state?.token,
        },{});
        if (response.data.success == '0') {
          setVerificationLabel(response.data.data.caption)
        }
    } catch (error) {
        console.error('An error occurred');
    }

  };
  return (
    <div className="common-box common-box-sticky">
        <div className="common-box-heading">Specification Availability</div>

        <div className="row">
            <div className="col-12 specification-availability-bx">
                <div className="specification-heading-bx">
                    <div className="sahb-lable">Operations</div>
                    <div className="sahb-lable">Permissions</div>
                </div>
    
                <div className="specification-item-bx">
                    <div className="saib-lable">Crypto Deposits</div>
                    <div className="saib-icon"><span className="active"><Icons.CheckSvg /></span></div>
                </div>

                <div className="specification-item-bx">
                    <div className="saib-lable">Crypto Withdrawals</div>
                    <div className="saib-icon"><span className={`${verificationLabel == 'Level 1' ? 'active' : ''}`}>{verificationLabel == 'Level 1' ? <Icons.CheckSvg /> : <Icons.XMarkSvg />}</span></div>
                </div>
       
                <div className="specification-item-bx">
                    <div className="saib-lable">Fiat Deposits</div>
                    <div className="saib-icon"><span className="active"><Icons.CheckSvg /></span></div>
                </div>
   
                <div className="specification-item-bx">
                    <div className="saib-lable">Fiat Withdrawals</div>
                    <div className="saib-icon"><span className={`${verificationLabel == 'Level 1' ? 'active' : ''}`}>{verificationLabel == 'Level 1' ? <Icons.CheckSvg /> : <Icons.XMarkSvg />}</span></div>
                </div>
 
                <div className="specification-item-bx">
                    <div className="saib-lable">Trading</div>
                    <div className="saib-icon"><span className="active"><Icons.CheckSvg /></span></div>
                </div>
            </div>
        </div>
    </div>
  )
}
