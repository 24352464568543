import React from 'react'
import { Modal } from 'react-bootstrap';

// Icon Files
import Icons from '../components/icons';


export default function TransactionDetailModal({ show, handleClose, data }) {
  console.log(data);
  
  return (
    <>
      <Modal className="zoom custom-content" centered show={show} onHide={handleClose}>
        <div className="custom-modal-header">
            <div className="cmh-lable">Transactions Detail</div>
            <span className="close-icon" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
                </svg>
            </span>
        </div>

        <div className="custom-modal-body">
          <div className="row mx-0 mb-3">
            <div className="col-12">
              <div className="transaction-item transaction-modal-item">
                <div className="ti-inner ti-i-pl">
                  <div className="ti-i-icon"><Icons.TransactionsSvg /></div>
                  <div className="ti-i-info"> </div>
                  <div className="ti-i-data">{data.transfer_type}</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">ID</div>
                  <div className="ti-i-data">{data.sequence}</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">Date</div>
                  <div className="ti-i-data">{data.created_at_formattad}</div>
                </div>
                {(data.transfer_type != 'transfer' && (data.user_wallet)) && 
                  <div className="ti-inner">
                    <div className="ti-i-info">Mt5 account number</div>
                    <div className="ti-i-data">{data.user_wallet.account_number}</div>
                  </div>
                }
                {(data.transfer_type == 'transfer' && (data.user_wallet)) &&
                  <>
                    <div className="ti-inner">
                      <div className="ti-i-info">From Mt5 account number</div>
                      <div className="ti-i-data">{data.user_wallet.account_number}</div>
                    </div>
                    <div className="ti-inner">
                      <div className="ti-i-info">To Mt5 to number</div>
                      <div className="ti-i-data">{data.to_user_wallet.account_number ?? 'wallet'}</div>
                    </div>
                  </> 
                }
                {/* <div className="ti-inner">
                  <div className="ti-i-info">Account</div>
                  <div className="ti-i-data">{data.type != "wallet" ? data.type : "mt5"}</div>
                </div> */}
                <div className="ti-inner">
                  <div className="ti-i-info">Payment Method</div>
                  <div className="ti-i-data"> {data?.payment_method?.name 
                    ? data.payment_method.name 
                    : `${(data.to_user_wallet && data.user_wallet.account_number) ? 'mt5' : 'wallet'} - ${(data.to_user_wallet && data.to_user_wallet.account_number) ? 'mt5' : 'wallet'}`
                  }</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">Balance</div>
                  <div className={"ti-i-data "+((data.transaction_status == 'done' || data.transaction_status == 'Done') ? 'color-green' :
                  (data.transaction_status == 'pending') ? 'color-yellow' :
                  'color-red')}>{data.computed_balance_usd+" USD"}</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">Amount</div>
                  <div className={"ti-i-data "+((data.transaction_status == 'done' || data.transaction_status == 'Done') ? 'color-green' :
                  (data.transaction_status == 'pending') ? 'color-yellow' :
                  'color-red')}>{data.computed_amount_usd +(data.currency ? ' '+ data.currency.code : " USD")}</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">Transaction Fee</div>
                  <div className="ti-i-data">0</div>
                </div>
                <div className="ti-inner">
                  <div className="ti-i-info">Status</div>
                  <div className={"ti-i-data "+((data.transaction_status == 'done' || data.transaction_status == 'Done') ? 'color-green' :
                  (data.transaction_status == 'pending') ? 'color-yellow' :
                  'color-red')}>{data.transaction_status}</div>
                </div>
                {data.reject_reason_description && (
                <div className="ti-inner ti-column">
                  <div className="ti-i-info">Reject Reason</div>
                  <div className="ti-i-data color-red">{data.reject_reason_description}</div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
