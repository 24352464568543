import React, { useState, useEffect, memo } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

// Icons & Images Common File
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

function TradingViewWidget() {

  // --- --- ----
 
  
  // --- --- ----

  // --- --- ---
  const [user, setUser] = useState('')
  const {state, dispatch } = useAuth();
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // --- ---
  const navigate = useNavigate();
  // --- ---

  // ---
  const [isDropActive, setIsDropActive] = useState(false);

  const handleMouseEnter = () => {
      setIsDropActive(true);
  };

  const handleMouseLeave = () => {
      setIsDropActive(false);
  };

  const handleItemClick2 = () => {
      setIsDropActive(true);
  }

  const handleItemClick = () => {
      setIsDropActive(false);
  };
  // ---

  const handleLogout = () => {
      dispatch({ type: 'LOGOUT'});
      navigate('/');
  }

  // --- ---
  useEffect(() => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      if (isUserLoggedIn) {
          setUserLoggedIn(true);
          setUser(loggedInUser);
      } else {
          navigate('/');
      }
  }, [state.userData, navigate]);


  return (
    <>

    {/* --- Trading terminal header start --- */}
    <div className="tradingview-header">

      {/* --- Header logo start --- */}
      <Link to="/dashboard" className="tvh-logo" target="_blank">
          <img src={Img.logo_light} alt="" />
      </Link>
      {/* --- Header logo end --- */}


      {/* --- Header right start --- */}
      <div className="tvh-right">
        <div className={`cr-drop-item ${isDropActive ? 'active' : ''}`}  onMouseLeave={handleMouseLeave}>
            <div className="ch-user-bx" onClick={handleItemClick2} onMouseEnter={handleMouseEnter}>
                {/* <div className="cr-icons-link"><Icons.UserSvg></div> */}

                <div className="cub-img-bx">
                    <img src={user?.image || Img.user} alt="" />
                </div>

                {/* <div className="cub-text-bx">User Name <Icons.DownSvg/></div> */}
            </div>
            <div className={`cr-drop-bx ${userLoggedIn} ${isDropActive ? 'active' : ''}`} onClick={handleItemClick}>
                <div className="cr-drop-inner">
                    <div className="cdi-main w-186px">
                        <Link to="/settings" className="cdi-user-data">
                            <div className="cud-name">{user?.first_name} {user?.last_name}</div>
                            <div className="cud-other">{user?.email}</div>
                        </Link>
                        <NavLink to="/dashboard" className="cdi-main-item"><Icons.DashboardSvg/> Dashboard</NavLink>
                        <NavLink to="/notifications" className="cdi-main-item"><Icons.NotificationsSvg/> Notifications <div className="cmi-num">99</div></NavLink>
                        <div className="cdi-main-item" onClick={handleLogout}><Icons.LogoutSvg/> Log Out</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* --- Header right end --- */}

    </div>
    {/* --- Trading terminal header end --- */}


    {/* --- Trading terminal body start --- */}
    <div className="tradingview-body">

      {/* --- */}
      <div className="tvb-left">

      </div>
      {/* --- */}


      {/* --- */}
      <div className="tvb-right">

        {/* --- */}
        <div className="tvg-right-flex">

          {/* --- */}
          <div className="tvg-r-left">
            <iframe
              title="terminal"
              className="mt5-terminal"
              src="https://trade.metatrader5.com/terminal"
              style={{ width: '100%', height: '100%', border: 'none' }}
              allowFullScreen
            />
          </div>
          {/* --- */}


          {/* ---- */}
          <div className="d-none tvg-r-right common-scrollbar-hide">
          </div>
          {/* ---- */}

        </div>
        {/* --- */}

      </div>
      {/* ---  */}

    </div>
    {/* --- Trading terminal body end --- */}
    </>
  );
}

export default memo(TradingViewWidget);
