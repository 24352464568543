import React from 'react'
import { Link } from 'react-router-dom';

// Design Files
import Breadcrumb from '../../components/Breadcrumb';

// Icons Files
import * as Img from '../../components/Img';

// winprofx5setup exe file
import tradeSteup from "../../img/winprofx5setup.exe";



const PagePlatfrom = () => {
  return (
    <>
      <Breadcrumb breadcrumbIcon="PlatfromSvg" breadcrumbHeading="Platfrom" />
      <div className="container-lg pt-3 pb-3 px-lg-4">
        <div className="row row-gap-4">

          <div className="col-12">
            <div className="platfrom-box pb-right">
              
              <div className="pb-img-box">
                <img src={Img.mobile} alt="" />
                <img src={Img.mobilefull} alt="" />
              </div>

              <div className="pb-tag">TRADING PLATFORM</div>

              <div className="pb-heading">Download MT 5 <br /> <span>For Mobile</span></div>

              <div className="playstores-btn">
                <Link className="playstores-item" target="_blank" to="https://winprofx.com/assets/images/metatrader5.apk">
                  <img src={Img.playStore} alt="" />
                  <div className="playstores-data">
                    <div className="psd-lable">GET IT FOR</div>
                    <div className="psd-heading">Google Play</div>
                  </div>
                </Link>

                <Link className="playstores-item" target="_blank" to='https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709?utm_campaign=install.metaquotes&utm_source=www.metatrader5.com'>
                  <img src={Img.macOS} alt="" />
                  <div className="playstores-data">
                    <div className="psd-lable">GET IT FOR</div>
                    <div className="psd-heading">App Store</div>
                  </div>
                </Link>
              </div>

            </div>
          </div>

          <div className="col-12">
            <div className="platfrom-box">

              <div className="pb-img-box">
                <img src={Img.laptop} alt="" />
                <img src={Img.laptopfull} alt="" />
              </div>

              <div className="pb-tag">TRADING PLATFORM</div>

              <div className="pb-heading">Download MT 5 <br /> <span>For Web</span></div>

              <div className="playstores-btn">
                <a className="playstores-item" download={true} href={tradeSteup} >
                  <img src={Img.windowos} alt="" />
                  <div className="playstores-data">
                    <div className="psd-lable">GET IT FOR</div>
                    <div className="psd-heading">Windows</div>
                  </div>
                </a>

                <div className="playstores-item" style={{filter:"grayscale(1) opacity(0.7)", pointerEvents:"none"}}>
                  <img src={Img.macOS} alt="" />
                  <div className="playstores-data">
                    <div className="psd-lable">Coming Soon</div>
                    <div className="psd-heading">mac OS</div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default PagePlatfrom