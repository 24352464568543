import React from 'react'
import Icons from '../../components/icons'

export default function NotificationItem({ className, heading,rejectReason, text, status, dateTime, onClick }) {
  return (
    <div className="col-12 notification-outer" onClick={onClick}>
        <div className={`notification-item ${className}`}>
            <div className="ni-icon"><Icons.NotificationsSvg/></div>
            <div className="ni-data">
                {( heading && <div className="ni-label">{heading}</div> )}
                {( rejectReason && <div className="ni-text">{rejectReason}</div> )}
                <div className='ni-status-date'>
                  <div className="ni-status">Status: <span className={(text=='done' || text=='Done' || text=='approved') ? "color-green" :"color-red"} >{text}</span></div>
                  {( dateTime && <div className="ni-date-time">{dateTime}</div> )}
                </div>
            </div>
        </div>
    </div>
  )
}
